import { grayScaleTheme } from "components/Layout/Theme";

export const DividerLine = function () {
  return (
    <div style={{ padding: "16px 0" }}>
      <div
        style={{
          height: "1px",
          backgroundColor: grayScaleTheme.grayScaleColor300,
        }}
      ></div>
    </div>
  );
};
