import React, { FunctionComponent } from "react";
import MainIntroCard from "../components/Solution/MainIntroCard";
import Spacer8em from "components/StyleComponent/StyleComponents";
import LoveMyRenIcon from "../components/Solution/LoveMyRenIcon";
import BlogContents from "components/FourthContents/BlogContents";
import Footer from "components/Layout/Footer";
import ThirdContents from "components/ThirdContents/ThirdContents";
import FourthContents from "components/FourthContents/FourthContents";
import PopupComponent from "components/ActiveComponents/PopupComponent";
import Menu from "components/Layout/Menu";

const Solution: FunctionComponent = function () {
  return (
    <>
      <Menu />
      <MainIntroCard
        title={"Deliver personalized experiences"}
        subTitle={"Usage-Based Insurance"}
        explainText={
          "Nurture loyalty, satisfaction, and retention with next-generation user experiences, timely support, and accurate discounts, all through the smartphone."
        }
      />
      <Spacer8em />
      {/* 인터렉션 페이지 추가해야함 */}
      <LoveMyRenIcon title={"Why user love MyRen"} />
      <BlogContents />
      <ThirdContents />
      <FourthContents />
      <PopupComponent />
      <Footer />
    </>
  );
};
export default Solution;
