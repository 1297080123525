import styled from "@emotion/styled";

export const CardSectionFlex = styled.div`
  height: 100%;
  padding: 191px 0;
  align-items: center;
  display: flex;
  @media screen and (max-width: 991px) {
    heigth: auto;
    padding: 38px 0;
  }
`;

export const FlexChildStretchContainer = styled.div`
  position: static;
  flex: 1;
  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  display: block;
  @media screen and (min-width:320px) and (max-width:768px){
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  }

  
`;

export const SolutionContainer = styled.div`
  width: 90%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    &.reverse {
      flex-direction: column-reverse;
    }
  }
`;

export const AnimationContents = styled.div`
  background-color: #121212;
  width: 39.58rem;
  position: relative;

  @media screen and (max-width: 991px) {
  }
`;

export const SolutionTextContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  // width: 25.69em;
`;

export const SolutionMainH3 = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
  font-family: Maven Pro, sans-serif;
  font-size: 1.777em;
  font-weight: 600;
  line-height: 1.3;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    font-weight: 700;
    letter-spacing: -0.7px;
    font-size: 1.13em; //40px
    line-height: 1.5rem;
  }
`;

export const SolutionDetailP = styled.p`
  //max-width: 45ch;
  margin-bottom: 1.6rem;
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 0;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    font-weight: 300;
    letter-spacing: -0.6px;
    font-size: 0.88em; //40px
    line-height: 1.25rem;
  }
`;

export const Spacer2em = styled.div`
  height: 2rem;
`;
