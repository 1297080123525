import Footer from "components/Layout/Footer";
import {
  LeftIconTypo,
  MainIcon,
  MenuWrapper,
  TopContents,
} from "components/Layout/Menu.style";
import { grayScaleTheme } from "components/Layout/Theme";
import {
  BoldDisplay05,
  BoldHeadLine02,
} from "components/StyleComponent/FontStyle";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const PolicyIOS: FunctionComponent = function () {
  return (
    <>
      <MenuWrapper>
        <TopContents>
          <Link to={"/main"}>
            <MainIcon src="./img/Logo.png" />
          </Link>
          <LeftIconTypo>개인정보처리방침</LeftIconTypo>
        </TopContents>
      </MenuWrapper>
      {/* 컨텐츠 */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={
            window.innerWidth >= 320 && window.innerWidth <= 768
              ? {
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                  marginBottom: "52px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
              : {
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "100px",
                  marginBottom: "100px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
          }
        >
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <BoldHeadLine02>마이렌 개인정보처리방침</BoldHeadLine02>
          ) : (
            <BoldDisplay05>마이렌 개인정보처리방침</BoldDisplay05>
          )}
        </div>
        {/* 개인정보 처리방침 내용 */}
        <div
          style={window.innerWidth >= 320 && window.innerWidth <= 768?{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "24px",
            paddingRight: "24px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }:{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "72px",
            paddingRight: "72px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }}
        >
          개인정보 처리 방침
<br />
<br />
주식회사 마이렌(이하 “회사”)는 회사가 운영하는 마이렌의 “어플리케이션”을 통하여 회사가 제공하는 서비스(이하 "서비스")를 이용하는 이용자님의 개인 정보를 보호하기 위하여 「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.
<br />
<br />
<br /><br />
회사는 개인정보 처리 방침을 통하여 이용자님의 개인정보가 어떠한 목적과 방식으로 수집•이용되고 있으며, 개인정보 보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.
<br />
<br />
<br /><br />
본 개인정보 처리 방침은 관련 법령의 개정이나 회사의 정책에 의해 변경될 수 있으며 회사는 어플리케이션의 초기화면 또는 어플리케이션 내 별도의 페이지 등을 통하여 이를 알려드리오니, 유의하여 주시기 바랍니다.
<br />
<br />
<br /><br />
본 개인정보 처리방침의 내용은 다음과 같습니다.
<br />
<br />
<br /><br />
1. 수집하는 개인정보의 항목 및 수집방법
<br /><br />
2. 개인정보의 수집 및 이용목적
<br /><br />
3. 개인정보의 제공
<br /><br />
4. 개인정보의 처리위탁
<br /><br />
5. 개인정보 국외이전 관련 절차
<br /><br />
6. 개인정보의 보유 및 이용기간
<br /><br />
7. 개인정보의 파기절차 및 방법
<br /><br />
8. 이용자의 권리와 의무
<br /><br />
9. 개인정보 자동 수집의 목적 및 거부에 관한 사항
<br /><br />
10. 개인정보의 기술적/관리적 보호 대책
<br /><br />
11. 개인정보보호책임자 및 민원 처리 콜센터
<br /><br />
12. 권익침해 구제방법
<br /><br />
13. 고지 의무
<br />
<br />
<br />
<br />
<br />
<br />
<br /><br />
1.  수집하는 개인정보의 항목 및 수집방법
<br />
<br />
<br /><br />
“회사”는 고객(이하 “이용자”)이 서비스를 이용하는 경우 아래와 같이 개인정보를 수집 및 이용하고 있습니다.
<br />
<br />
<br /><br />
A.   수집하는 개인정보 항목
<br />
<br />
<br /><br />
가. 회원가입을 위해 필요한 정보
<br />
<br />
<br /><br />
1)   마이렌 이용자정보
<br />
<br />
<br />
                                     i.    (필수) 아이디(이메일), 이용자 성명, 보호자 성명, 연락처
                                     <br />
                                     <br />
                                     <br /><br />
                                   ii.    (만14세 미만 아동의 경우) 법정대리인의 성명, 연락처
                                   <br />
                                   <br />
                                   <br /><br />
                                  iii.    (필수) 자동차 번호, 자동차 가입 보험사
                                  <br />
                                  <br />
                                  <br />
                                  <br /><br />
나. 서비스 및 기능 이용을 위해 필요한 정보
<br />
<br />
<br /><br />
1)   서비스 단말기의 위치정보
<br />
<br />
<br /><br />
2)   서비스 단말기의 기기정보 및 서비스 서버 접속 정보 (IP Address, Device Id, 접속기록 등)
<br />
<br />
<br /><br />
다. 리워드를 위한 수집항목
<br />
<br />
<br /><br />
1)   리워드를 위한 개인정보 수집항목 : 아이디, 이용자 이름, 이동전화번호, 어플 사용시간, 제휴사 연계 리워드의 경우 제휴사 아이디
<br />
<br />
<br /><br />
라. 콜센터 운영에 따른 수집항목
<br />
<br />
<br /><br />
1)   콜센터 상담시 개인 식별을 위한 수집항목 : 성명, 이동전화번호
<br />
<br />
<br /><br />
마. 실증 데이터 수집을 위한 보행자/운전자 정보 수집항목
<br />
<br />
<br /><br />
1)   실증 데이터 수집을 위한 보행자/운전자 모집, 데이터 수집 용역시 수집항목 : 아이디, 성명, 이동전화번호, 어플 사용시간, 보행자/운전자 단말의 센서 및 GPS 정보
<br />
<br />
<br /><br />
바. 온라인 광고 트래커를 위해 필요한 수집항목
<br />
<br />
<br /><br />
1)   리워드 기반 CPA 광고 모바일 트래커를 위해 필요한 수집항목 : 광고식별값 (ADID, IDFA), 플랫폼 정보 (AOS, iOS), 어트리뷰션 시 광고 매체 파트너사 정보, 접속 IP 주소, 네트워크 (3G, Wifi), 디바이스 모델명 / 제조사명 / 해상도 / 통신사명 / 설정 언어 / 국가, 마켓 인스톨러 데이터
<br />
<br />
<br />
<br />
<br /><br />
2.  개인정보 수집 및 이용 목적
<br />
<br />
<br /><br />
회사는 본 서비스와 관련하여 수집한 개인정보를 다음의 목적을 위해 활용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다. 수집된 서비스 단말기의 위치 정보는 타 단말에 위치정보를 공유하여 위험 알림 서비스를 제공합니다.
<br />
<br />
<br /><br />
A.   이용자 관리 : 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량 이용자의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입 제한, 분쟁 처리를 위한 기록 보존, 불만 처리, 공지사항 전달 등
<br />
<br />
<br /><br />
B.   고객 서비스 : 각종 위험 알람 서비스 제공, 리워드 및 각종 편의 서비스 제공, 사용자들의 이용문의 및 불편 해소 대응을 위한 상담센터 운영 등
<br />
<br />
<br /><br />
C.   제3자에 대한 고객 서비스 제공 : 서비스 단말기의 위치 정보 공유를 통한 각종 위험 알림 서비스 및 각종 편의 서비스 제공 등
<br />
<br />
<br /><br />
D.   제품 개발 활용 : 로그 분석을 통한 기능개선 및 맞춤 서비스 제공, 관심분야를 파악하여 제품개발 등
<br />
<br />
<br /><br />
E.   이용자 정보 및 제품 정보 활용을 통한 추가적인 스마트 서비스 제공 등
<br />
<br />
<br /><br />
F.   실증서비스 제공에 대한 쿠폰 제공 등
<br />
<br />
<br />
<br />
<br /><br />
3.  개인정보의 제3자 제공
<br />
<br />
<br /><br />
“회사”는 “이용자”의 동의가 있거나 관련 법령의 규정이 있는 경우 등을 제외하고는 이용자의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만 아래 각 호에 기재된 바와 같이 통계 데이터 수집 등의 서비스 제공을 위하여 이용자의 개인정보를 다른 제3자에게 제공합니다.
<br />
<br /><br />
가.    제공받는 자의 개인정보의 이용 목적: 교통환경 분석 및 실증 효과성 검증 등을 위한 통계 데이터 제공
<br />
<br />
<br /><br />
나.    제공하는 개인정보 항목: 사고감지시간, 사고 유형, 사고를 감지한 단말의 식별자/타입/위도/경도/방향/속도
<br />
<br /><br />
다.    제공받는 자의 보유·이용 기간: 회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 다만, 제공받는 자는 관련법령의 규정에 의하여 개인정보를 보유할 필요가 있는 경우 또는 내부 방침에 의하여 보존할 필요가 있는 경우 해당 법령에서 정한 바에 따라 개인정보를 보관할 수 있습니다.
<br />
<br />
<br />
<br />
<br /><br />
4.  개인정보의 처리위탁
<br />
<br />
<br /><br />
회사는 보다 나은 서비스 제공과 이용자편의 제공 등 업무 수행을 원활하게 하기 위하여 외부 전문업체에 이용자님의 개인정보에 대한 수집·보관·처리·이용·제공·관리·파기 등(이하 "처리")을 위탁할 수 있습니다.
<br />
<br />
<br /><br />
5.  개인정보 국외이전 관련 절차
<br />
<br />
<br /><br />
"회사"는 이용자님들의 정보를 해외에 있는 다른 회사로 제공·처리위탁하거나 해외에서 보관하려는 경우에는 추가로 이용자님에게 아래 사항을 모두 고지하고 동의 받는 절차를 거칠 것입니다.
<br />
<br />
<br /><br />
A.   이전되는 개인정보의 항목,
<br />
<br />
<br /><br />
B.   개인정보가 이전되는 국가, 이전 일시 및 이전 방법,
<br />
<br />
<br /><br />
C.   개인정보를 이전 받는 자의 성명(법인인 경우에는 그 명칭 및 정보관리책임자의 연락처) 및
<br />
<br />
<br /><br />
D.   개인정보를 이전 받는 자의 개인정보 이용목적 및 보유, 이용 기간
<br />
<br />
<br /><br />
※ 한편 “이용자”가 회사가 제공하는 “서비스”를 이용하기 위한 개별 서비스 가입 과정에서 추가적으로 개인정보의 국외 이전이 필요한 경우, 해당 개별 서비스 가입 과정에서 동의 받도록 하겠습니다 .
<br />
<br />
<br />
<br />
<br /><br />
6.  개인정보의 보유 및 이용기간
<br />
<br />
<br /><br />
회사는 정보주체로부터 개인정보를 수집하는 경우 서비스 이용기간 동안(탈퇴 시까지) 개인정보를　이용•보관함을 원칙으로 합니다. 다만 개인정보 보호법 제39조의 6및 같은 법 시행령 제48조의 5에 따라 1년간 서비스 이용 기록이 없는 경우 휴면회원으로 전환되며, 휴면회원으로 전환될 경우 관련된 정보를 파기하거나 별도의 DB로 분리 보관됩니다. 이 경우 회사는 휴면 전환 30일까지 이용자에게 별도로 분리 보관되는 사실 및 내용을 Push, SMS, 이메일을 통해서 이용자에게 알리겠습니다. 또한, 회사는 관계법령의 규정 및 회사 내부 방침에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정한 기간 동안 개인 정보를 보관합니다.
<br />
<br />
<br /><br />
구분: 서비스 부정 이용자 정보 (아이디, 기기 정보, 서비스 이용 로그 및 수집 항목)
<br />
<br />
<br /><br />
보존기간: 그 분쟁이 해결될 때까지
<br />
<br />
<br /><br />
구분: 회사와 이용자간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우
<br />
<br />
<br /><br />
보존기간: 그 분쟁이 해결될 때까지
<br />
<br />
<br /><br />
구분: 개별적으로 이용자님의 동의를 받은 경우
<br />
<br />
<br /><br />
보존기간: 동의를 받은 기간
<br />
<br />
<br /><br />
구분 : 서비스 이용 로그
<br />
<br />
<br /><br />
보존기간 : 통계 및 서비스 개선 목적으로 최대 2년 보관
<br />
<br />
<br /><br />
구분 : 시스템 로그
<br />
<br />
<br /><br />
보존기간 : 30일간 보관
<br />
<br />
<br /><br />
※ 스마트 가전 기기 정보, 연결 기기 정보, 서비스 이용 로그 및 스마트 어플리케이션 이용 로그 정보 중 일부는 통계 및 서비스 개선 목적으로 개인을 식별할 수 없는 형태로 보관될 수 있습니다.
<br />
<br />
<br />
<br />
<br /><br />
7.  개인정보의 파기절차 및 방법
<br />
<br />
<br /><br />
회사”는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
<br />
<br />
<br /><br />
개인정보 파기의 절차 및 방법은 다음과 같습니다.
<br />
<br />
<br /><br />
A.   파기절차
<br />
<br />
<br /><br />
개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 회원 탈퇴 등의 별도 요청없이 모바일 앱 삭제 이후에도 개인정보 파기를 원하는 경우 별도의 요청이 필요합니다.
<br />
<br />
<br /><br />
B.   파기방법
<br />
<br />
<br /><br />
“회사”는 보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다. 전자적 파일 형태로 기록ㆍ저장된 개인정보는 기록을 재생할 수 없도록 로우 레벨 포멧(Low Level Format) 등의 기술적 방법을 이용하여 파기하며, 종이 문서에 기록ㆍ저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
<br />
<br />
<br />
<br />
<br /><br />
또한 상법, 국세기본법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 이 경우 회사는 보관하는 개인정보를 그 보관의 목적으로만 이용하며 보존 기간 및 보존 항목은 아래와 같습니다.
<br />
<br />
<br /><br />
- 계약 또는 청약철회 등에 관한 기록: 5 년(전자상거래 등에서의 소비자보호에 관한 법률)
<br />
<br />
<br /><br />
- 대금 결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
<br />
<br />
<br /><br />
- 소비자 불만 또는 분쟁 처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)
<br />
<br />
<br /><br />
- 이용자의 인터넷 등 로그 기록: 3개월(통신비밀보호법)
<br />
<br />
<br /><br />
- 통신사실확인자료 제공 시 “이용자” 확인에 필요한 성명, 전화번호 등: 12개월(통신비밀보호법)
<br />
<br />
<br />
<br />
<br /><br />
8.  이용자의 권리와 의무
<br />
<br />
<br /><br />
이용자님의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자님이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자님 자신에게 귀속되며 타인 정보의 도용 등 허위 정보를 입력할 경우 이용자 자격이 상실될 수 있습니다. 이용자님은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 이용자님의 개인정보가 유출되지 않도록 주의하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 권리를 훼손할 시에는 『개인정보 보호법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 기타 관련 법률 등에 의해 처벌 받을 수 있습니다.
<br />
<br />
<br /><br />
A.   개인정보 열람, 정정 삭제 요구의 권리
<br />
<br />
<br /><br />
“이용자”와 그 법정대리인은 언제든지 등록되어 있는 자신 혹은 해당 미성년자 “이용자”의 개인정보를 열람하거나 정정할 수 있고 삭제를 요구할 수 있습니다. 개인정보 열람, 정정 및 삭제를 하고자 할 경우에는 “회사”의 개인정보관리책임자 및 담당자에게 서면, 전화 또는 전자우편으로 연락하면 지체 없이 조치하도록 하겠습니다.
<br />
<br />
<br /><br />
다만, “이용자”는 다른 법령에서 그 개인정보가 수집대상으로 명시되어 있는 경우에는 삭제를 요구할 수 없으며, 개인정보를 정정ㆍ삭제하는 경우에 일부 또는 전부의 서비스 이용이 불가능할 수 있습니다.
<br />
<br />
<br /><br />
B.   개인정보 수집, 이용, 제공에 대한 동의 철회의 권리
<br />
<br />
<br /><br />
“이용자”와 그 법정대리인은 언제든지 등록되어 있는 자신 혹은 해당 미성년자 “이용자”의 개인정보의 수집, 이용, 제공에 대한 동의를 철회할 수 있습니다. 이를 위하여 “이용자”는 서면 기타 방법으로 해지의사를 통지함으로써 언제든지 이용계약을 해지할 수 있고, “회사”의 개인정보관리책임자 및 담당자에게 서면, 전화 또는 전자우편으로 연락할 수도 있습니다. 이러한 경우 “회사”는 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.
<br />
<br />
<br />
<br />
<br /><br />
9.  개인정보 자동 수집의 목적 및 거부에 관한 사항
<br />
<br />
<br /><br />
A.   자동수집항목
<br />
<br />
<br /><br />
회사는 서비스 운영을 위해 이용자의 앱 기능 실행시 접속 로그(IP포함), 단말 기기 위치 정보, 연결 기기 정보 (OS, 국가 및 언어, Time zone, 식별정보 등)  및 기기 사용 정보 등 서비스 이용에 필요한 정보를 자동으로 수집하게 됩니다. 단말 기기 위치 정보 수집을 거부하는 경우는 위치 기반 위험분석 특성상 서비스를 이용할 수 없습니다.
<br />
<br />
<br /><br />
B.   자동수집 목적
<br />
<br />
<br /><br />
가. 위험분석 알람 서비스 제공
<br />
<br />
<br /><br />
나. 최적화된 서비스 제공
<br />
<br />
<br /><br />
다. 지능형 맞춤 서비스 제공을 위한 기기 사용 행태 (ex. 보행자/운전자 패턴 학습 등) 분석 등
<br />
<br />
<br /><br />
라. 제품 수행 결과 확인 및 제품 연구 목적 등
<br />
<br />
<br /><br />
C. 광고 식별자 수집
<br />
<br />
<br /><br />
※ 회사는 이용자의 가입경로 분석, 리워드 기반 이용자 모집 광고 등을 제공하기 위하여 ‘광고식별자 (ADID / IDFA)’를 수집합니다. 이는 보다 나은 서비스를 제공하기 위함입니다. 이용자는 언제든지 수신을 차단할 수 있으며, 수신 차단 방법은 아래와 같습니다.
<br />
<br />
<br /><br />
l 스마트폰 (모바일앱)의 광고식별자 차단/허용
<br />
<br />
<br /><br />
ü  Android : 설정 &#62; 개인정보보호 &#62; 광고 &#62; 광고ID 재설정 또는 광고ID 삭제
<br />
<br />
<br /><br />
ü  iOS : 설정 &#62; 개인정보보호 &#62; 추적 &#62; 앱이 추적을 요청하도록 허용 끔
<br />
<br />
<br /><br />
모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.
<br />
<br />
<br />
<br />
<br /><br />
10.   개인정보 보호를 위한 기술적, 관리적 보호 장치
<br />
<br />
<br /><br />
“회사”는 “이용자”들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
<br />
<br />
<br /><br />
A.   기술적 보호조치
<br />
<br />
<br /><br />
- “회사”는 “이용자”의 개인정보를 암호 알고리즘을 이용하여 보호하며, 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다. 단, 광고 트래커의 경우는 제외됩니다.
<br />
<br />
<br /><br />
- “회사”는 해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 기술적 장치를 갖추어 개인정보 보안에 만전을 기하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 패스워드 등 본인임을 인증하는 정보에 대해서는 일방향 암호화하여 저장합니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보 침해사고 발생에 대응하기 위해 개인정보처리시스템에 대한 접속기록을 보관하고 백업 조치 등 위조·변조 방지를 위한 조치를 취하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보 처리 시스템에서 개인정보의 출력 시(인쇄, 화면표시, 파일생성 등) 용도를 특정하며, 용도에 따라 출력 항목을 최소화합니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보 취급자가 개인 정보를 종이로 인쇄하거나, 디스켓, 콤팩트디스크 등 이동 가능한 저장매체에 복사할 경우 개인정보 관리 책임자의 사전 승인을 받도록 조치합니다. 출력, 복사물로부터 다시 출력 또는 복사하는 경우도 또한 같습니다.
<br />
<br />
<br /><br />
B.   관리적 보호조치
<br />
<br />
<br /><br />
- “회사”는 “이용자”의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 전보 또는 퇴직 등 인사 이동이 발생하여 개인정보취급자가 변경되었을 경우에 지체 없이 개인 정보처리시스템의 접근 권한을 변경 또는 말소합니다. 이 경우 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 3년 간 보관합니다.
<br />
<br />
<br /><br />
- “회사”는 취급 중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보취급자의 PC를 설정합니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보를 수집·이용 및 파기하는 경우 관련 법령이 권고하는 기준에 따라 수집 목적, 이용기간, 활용 정보 항목, 파기 등에 대한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호의무 등에 관해 정기적인 사내교육 및 외부 위탁교육을 실시하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 모든 임직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.
<br />
<br />
<br /><br />
- “회사”는 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도 분리하여 보관하고 있습니다.
<br />
<br />
<br />
<br />
<br /><br />
11.   개인정보보호책임자 및 민원처리 이메일
<br />
<br />
<br /><br />
회사는 이용자님의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다.
<br />
<br />
<br /><br />
- 개인정보 보호 책임자 : 주식회사 마이렌 정보보호담당
<br />
<br />
<br /><br />
- 이메일 : info@myren.kr
<br />
<br />
<br />
<br />
<br /><br />
12.   권익침해 구제방법
<br />
<br />
<br /><br />
개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
<br />
<br />
<br /><br />
아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
<br />
<br />
<br /><br />
- 개인정보 침해신고센터 (한국인터넷진흥원 운영)
<br />
<br />
<br /><br />
소관업무 : 개인정보 침해사실 신고, 상담 신청
<br />
<br />
<br /><br />
홈페이지 : privacy.kisa.or.kr
<br />
<br />
<br /><br />
전화 : (국번없이) 118
<br />
<br />
<br /><br />
주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원
<br />
<br />
<br /><br />
- 개인정보 분쟁조정위원회
<br />
<br />
<br /><br />
소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
<br />
<br />
<br /><br />
홈페이지 : www.kopico.go.kr
<br />
<br />
<br /><br />
전화 : 1833-6972
<br />
<br />
<br /><br />
주소 : 서울특별시 종로구 세종대로 209 정부서울청사 4층
<br />
<br />
<br /><br />
- 대검찰청 사이버범죄수사단
<br />
<br />
<br /><br />
(국번없이) 1301, cid@spo.go.kr (www.spo.go.kr)
<br />
<br />
<br /><br />
- 경찰청 사이버안전국
<br />
<br />
<br /><br />
(국번없이) 182 (cyberbureau.police.go.kr)
<br />
<br />
<br /><br />
※ 이용자님은 회사의 본 서비스를 이용하시면서 발생하는 모든 개인정보보호 관련 민원을 개인정보관리 책임자에게 신고할 수 있습니다. 회사는 이용자님들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
<br />
<br />
<br />
<br />
<br /><br />
13.   고지 의무
<br />
<br />
<br /><br />
이 개인정보처리방침은 법령ㆍ정책 또는 보안 기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 아래와 같은 방법으로 사전 공지하도록 하겠습니다.
<br />
<br />
<br /><br />
- 스마트폰 어플리케이션 메시지를 통하여 수정내용 공지
<br />
<br />
<br /><br />
- 이메일, Push, SMS 등을 통하여 수정내용 공지
<br />
<br />
<br />
<br />
<br /><br />
본 개인정보 처리방침은 2023년 7월 27일부터 시행합니다.
<br />
<br /><br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PolicyIOS;
