import Footer from "components/Layout/Footer";
import {
  LeftIconTypo,
  MainIcon,
  MenuWrapper,
  TopContents,
} from "components/Layout/Menu.style";
import { grayScaleTheme } from "components/Layout/Theme";
import {
  BoldDisplay05,
  BoldHeadLine02,
} from "components/StyleComponent/FontStyle";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const LocationPolicy: FunctionComponent = function () {
  return (
    <>
      <MenuWrapper>
        <TopContents>
          <Link to={"/main"}>
            <MainIcon src="./img/Logo.png" />
          </Link>
          <LeftIconTypo>위치기반 서비스 이용 동의</LeftIconTypo>
        </TopContents>
      </MenuWrapper>
      {/* 컨텐츠 */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={
            window.innerWidth >= 320 && window.innerWidth <= 768
              ? {
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                  marginBottom: "52px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
              : {
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "100px",
                  marginBottom: "100px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
          }
        >
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <BoldHeadLine02>마이렌 위치기반 서비스 이용 동의</BoldHeadLine02>
          ) : (
            <BoldDisplay05>마이렌 위치기반 서비스 이용 동의</BoldDisplay05>
          )}
        </div>
        {/* 개인정보 처리방침 내용 */}
        <div
          style={window.innerWidth >= 320 && window.innerWidth <= 768?{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "24px",
            paddingRight: "24px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }:{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "72px",
            paddingRight: "72px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }}
        >
          <br />
          제1조 (목적) 이 약관은 주식회사 마이렌(이하 “회사”)가 제공하는
          위치기반서비스와 관련하여 “회사”와 개인위치 정보주체와의 권리, 의무 및
          책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          <br />
          <br />
          제2조 (약관 외 준칙)
          <br />
          <br />
          이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률,
          정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법,
          전기통신사업법 등 관계법령과 “회사”의 이용약관 및 개인정보 처리방침,
          “회사”가 별도로 정한 지침 등에 의합니다.
          <br />
          <br />
          제3조 (서비스 내용 및 요금)
          <br />
          <br />
          1. 회사는 아래와 같은 위치기반 서비스를 제공합니다.
          <br />
          <br />
          - 마이렌 서비스(이하 “서비스”) : 이용자의 정보, 이용자의 단말(이하
          “단말”) 자체 센서를 기반으로 사고 발생 시, 편의 및 안전을 보조하는
          위험정보 제공(알림) 서비스(차량 충돌, 자전거 충돌, 휠체어 충돌, 보행자
          충돌 및 낙상)를 제공할 수 있는 스마트폰 어플리케이션 서비스를
          칭합니다.
          <br />
          <br />
          2. 서비스는 실증 사용자에 한해 기본적으로 무료로 제공되며 타 단말에
          서비스 단말기의 위치정보 공유를 통하여 서비스가 가능합니다.
          <br />
          <br />
          3. 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각
          이동통신사의 정책에 따릅니다.
          <br />
          <br />
          제4조 (위치정보 이용방법)
          <br />
          <br />
          "회사"는 스마트폰, 태블릿PC 등 전자 단말기의 운영체계(Operation
          System)에서 제공하는 위치정보 API를 통해 제공받는 방식으로
          개인위치정보를 이용합니다.
          <br />
          <br />
          제5조 (개인위치정보의 이용 또는 제공)
          <br />
          <br />
          1. 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는
          미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다.
          <br />
          <br />
          <br />
          <br />
          2. 회사는 타사업자 또는 이용 고객과의 민원처리 등을 위해 위치정보
          이용•제공 사실 확인자료를 자동으로 기록•보존하며, 해당 자료는 1년간
          보관합니다.
          <br />
          <br />
          4. 회사는 서비스 제공을 위하여 이용자의 위치정보를 서비스를 이용하는
          다른 제3자에게 제공합니다. 다만, 제3자 제공시에는 개인의 위치가
          식별되지 않는 상태로 제공됩니다”
          <br />
          <br />
          제6조 (개인위치정보의 보유목적 및 보유기간)
          <br />
          <br />
          “회사”는 위험정보 제공 서비스를 위하여 이용자가 동의한 목적범위
          내에서만 이용하고, 개인위치정보의 수집, 이용 또는 제공목적을 달성할
          때에는 지체 없이 파기합니다.
          <br />
          <br />
          <br />
          <br />
          제7조 (개인위치정보주체의 권리)
          <br />
          <br />
          1. 이용자는 회사에 대하여 언제든지 개인위치정보를 이용한
          위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의 및 제3자
          제공에 관한 내용의 통보 횟수 또는 기간 등에 관한 동의의 전부 또는
          일부를 철회할 수 있습니다. 이 경우 회사는 지체 없이 수집한
          개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.
          <br />
          <br />
          2. 이용자는 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는
          제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고
          이를 위한 기술적 수단을 갖추고 있습니다.
          <br />
          <br />
          3. 이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를
          요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수
          있습니다. 이 경우 회사는 정당한 사유 없이 이용자의 요구를 거절할 수
          없습니다.
          <br />
          <br />
          가. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료
          <br />
          <br />
          나. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는
          다른 법률 규정에 의하여 제 3자에게 제공된 이유 및 내용
          <br />
          <br />
          4. 이용자는 제1 항 내지 제 3항의 권리행사를 위해 회사의 소정의 절차를
          통해 요구할 수 있습니다.
          <br />
          <br />
          제8조 (법정대리인의 권리)
          <br />
          <br />
          1. 회사는 만14세 미만 아동으로부터 개인위치정보를 수집•이용 또는
          제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를
          받아야 합니다.
          <br />
          <br />
          2. 법정대리인은 만14세 미만 아동의 개인위치정보를 수집•이용•제공에
          동의하는 경우 동의유보권, 동의철회권 및 일시중지권, 열람•고지 요구권을
          행사할 수 있습니다.
          <br />
          <br />
          제9조 (8세 이하의 아동 등의 보호의무자의 권리)
          <br />
          <br />
          1. 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라
          한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
          개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는
          것으로 봅니다.
          <br />
          <br />
          가. 8세 이하의 아동
          <br />
          <br />
          나. 피성년후견인
          <br />
          <br />
          다. 장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진
          자로서 장애인고용촉진 및 직업재활법 제2조 제2호의 규정에 의한
          중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여
          장애인등록을 한 자에 한한다)
          <br />
          <br />
          2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
          이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
          보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          <br />
          <br />
          3. 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
          동의하는 경우 본 이용약관 제6조에 열거된 개인위치정보주체 권리의
          전부를 행사할 수 있습니다.
          <br />
          <br />
          제10조 (손해배상)
          <br />
          <br />
          개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
          제15조 내지 제26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
          대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이
          없음을 입증하지 아니하면 책임을 면할 수 없습니다.
          <br />
          <br />
          제11조 (면책)
          <br />
          <br />
          1. 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여
          이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
          <br />
          <br />
          가. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
          <br />
          <br />
          나. 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의
          고의적인 서비스 방해가 있는 경우
          <br />
          <br />
          다. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우
          <br />
          <br />
          라. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유가 있는
          경우
          <br />
          <br />
          마. 이용자의 스마트 폰 오류에 의해 손해가 발생한 경우, 또는 이용자가
          개인정보 및 전자우편 주소 기타 "회사"에 제공하는 정보를 부실하게
          기재하여 손해가 발생한 경우
          <br />
          <br />
          바. 제 가 호 내지 제 마 호를 제외한 기타 회사의 고의•과실이 없는
          사유로 인한 경우
          <br />
          <br />
          2. 회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성
          등에 대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에
          대하여는 책임을 부담하지 아니합니다.
          <br />
          <br />
          3. 위치정보는 관련 기술의 발전 등에 따라 오차가 발생할 수 있는데,
          회사는 회사의 고의나 과실로 인한 것이 아닌 기술적인 이유로 인한 오차에
          대해 책임을 부담하지 아니합니다.
          <br />
          <br />
          제12조 (사업자 정보)
          <br />
          <br />
          회사의 상호, 주소, 전화번호 그 밖의 연락처(메일)는 다음과 같습니다.
          <br />
          <br />
          상호: 주식회사 마이렌
          <br />
          <br />
          주소: 충청남도 천안시 서북구 천안천4길 32, 그린스타트업타운 412호
          <br />
          <br />
          대표메일: info@myren.kr
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LocationPolicy;
