import React, { FunctionComponent } from "react";
import {
  PopupContainer,
  PopupContainerCata,
  PopupTextAreaSpace,
  PopupTextWrapper,
  PopupTextDiv,
  PopupButtonAreaSpace,
  PopupButtonA,
} from "../ComponentStyle/PopupItem.style";

export const PopupItem: FunctionComponent = function () {
  return (
    <PopupContainer>
      <PopupContainerCata>
        <PopupTextAreaSpace>
          <PopupTextWrapper>
            <PopupTextDiv>Start building your solution today</PopupTextDiv>
          </PopupTextWrapper>
        </PopupTextAreaSpace>
        <PopupButtonAreaSpace>
          <PopupButtonA className="button">Schedule a call</PopupButtonA>
        </PopupButtonAreaSpace>
      </PopupContainerCata>
    </PopupContainer>
  );
};

export default PopupItem;
