import SliderComponent from "components/FourthContents/SliderComponent";
import {
  defaultColorTheme,
  grayScaleTheme,
  mainTheme,
} from "components/Layout/Theme";
import {
  MediumBody01,
  SemiBoldHeadLine01,
} from "components/StyleComponent/FontStyle";
import { useRef, useState } from "react";
import styled from "@emotion/styled";
import TabContents from "./TabContents";

const TabMenu = styled.div`
  color: ${grayScaleTheme.grayScaleColor800}
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5%;

  .submenu {
    // 기본 Tabmenu 에 대한 CSS를 구현
    justify-content: center;
    width:152px;
    padding: 36px 0;
    font-size: 15px;
    border-bottom:4px solid ${defaultColorTheme.White};
    &:hover{
      color: ${grayScaleTheme.grayScaleColor600}
    }
    @media screen and (min-width:320px) and (max-width:768px){
    width:91px;
    padding: 14px 0;
    }
  }

  .focused {
    border-bottom:4px solid;
    color: ${mainTheme.mainColor500};
    &:hover{
    border-bottom:4px solid;
    color: ${mainTheme.mainColor500};
    }
  }

  & div.desc {
    text-align: center;
  }
`;
const Desc = styled.div`
  width: 95rem;
  text-align: center;
`;
export const Tab = () => {
  const element1 = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const element3 = useRef<HTMLDivElement>(null);
  const element4 = useRef<HTMLDivElement>(null);
  const element5 = useRef<HTMLDivElement>(null);
  const element6 = useRef<HTMLDivElement>(null);
  const element7 = useRef<HTMLDivElement>(null);
  const element8 = useRef<HTMLDivElement>(null);
  const element9 = useRef<HTMLDivElement>(null);
  const elementArray = [
    element1,
    element2,
    element3,
    element4,
    element5,
    element6,
    element7,
    element8,
    element9,
  ];
  const [currentTab, clickTab] = useState(0);
  const menuArr = [
    { name: "앱 실행", content: "Tab menu TWO" },
    { name: "로그인", content: "Tab menu ONE" },
    { name: "회원가입", content: "Tab menu THREE" },
    { name: "홈화면", content: "Tab menu TWO" },
    { name: "백그라운드 작동", content: "Tab menu ONE" },
    { name: "사고 감지 및 신고", content: "Tab menu TWO" },
    { name: "사고 대응요령", content: "Tab menu ONE" },
    { name: "사이드바 메뉴", content: "Tab menu ONE" },
    { name: "신고내역", content: "Tab menu ONE" },
  ];
  const menuContentsArr = [
    [
      "./img/App_1.png",
      "./img/App_2.png",
      "./img/App_3.png",
      "./img/App_4.png",
    ],
    ["./img/Login_1.png"],
    ["./img/Join_1.png", "./img/Join_2.png", "./img/Join_3.png"],
    ["/img/Home_1 copy.png"],
    // ["./img/Home_1.png", "./img/Home_2.png"],
    ["./img/Background_1.png"],
    ["./img/Accident_1.png"],
    ["./img/HowToAccident_1.png"],
    ["./img/SideBar_1.png"],
    ["./img/Record_1.png"],
  ];

  const selectMenuHandler = (index: number) => {
    // observer.observe(elementArray[index].current?)
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
    let top = 0;
    if (elementArray[index].current) {
      top = elementArray[index].current!.offsetTop;
      window.scrollTo({ top: top - 84, behavior: "smooth" });
    }
  };
  if (window.innerWidth >= 320 && window.innerWidth < 1200) {
    return (
      <div>
        <div
          style={{ position: "sticky", top: "0px", backgroundColor: "white" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "scroll",
            }}
          >
            <TabMenu>
              {menuArr.map((el, index) => (
                <div
                  className={
                    index === currentTab ? "submenu focused" : "submenu"
                  }
                  onClick={() => selectMenuHandler(index)}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {window.innerWidth >= 800 ? (
                      <SemiBoldHeadLine01>{el.name}</SemiBoldHeadLine01>
                    ) : (
                      <MediumBody01>{el.name}</MediumBody01>
                    )}
                  </div>
                </div>
              ))}
            </TabMenu>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
            flexDirection: "column",
          }}
        >
          {elementArray.map((ele, index) => (
            <TabContents
              RefElement={ele}
              ContentsArr={menuContentsArr[index]}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <TabMenu>
            {menuArr.map((el, index) => (
              <div
                className={index === currentTab ? "submenu focused" : "submenu"}
                onClick={() => selectMenuHandler(index)}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SemiBoldHeadLine01>{el.name}</SemiBoldHeadLine01>
                </div>
              </div>
            ))}
          </TabMenu>
          <div>
            <Desc>
              <SliderComponent
                currentTab={currentTab}
                contents={menuContentsArr[currentTab]}
              />
            </Desc>
          </div>
        </div>
      </div>
    );
  }
};

export default Tab;
