import { Icon } from "@iconify/react";
import { TypographyComponent } from "components/StyleComponent/FontStyle";
import { Spacer } from "components/StyleComponent/Spacer";
import React, { FunctionComponent } from "react";

type AccordionProps = {
  HiddenChildren?: React.ReactElement;
  AccordionTitle?: React.ReactElement;
  onClick?: () => void;
};

const Accordion: FunctionComponent<AccordionProps> = function ({
  HiddenChildren,
  AccordionTitle,
  onClick,
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        {AccordionTitle}
        <Icon
          icon={
            HiddenChildren
              ? "material-symbols:expand-more"
              : "ion:person-circle"
          }
          fontSize={"44px"}
        />
      </div>
      {HiddenChildren}
    </div>
  );
};

export default Accordion;
