import { FunctionComponent, useCallback, useState } from "react";
import {
  FooterWrapper,
  FooterLogo,
  FooterMenu,
  FooterImgWrapper,
} from "./Footer.style";
import {
  MediumBody01,
  RegularBody02,
  SemiBoldHeadLine02,
  TypographyStyle,
  TypographyComponent,
} from "components/StyleComponent/FontStyle";
import { defaultColorTheme, grayScaleTheme, primaryColorTheme } from "./Theme";
import { ContactBtn } from "./Menu.style";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ContentsColor } from "./Theme";
import { Spacer } from "components/StyleComponent/Spacer";
import Modal from "./Modal";
type FooterPropsType = {
  address: string;
  companyName?: string;
  companyNumberAndCeo: string;
  copyright: string;
  modalButtonTxt: {
    buttonTxt: string;
    email: string;
    emailExplain: string;
    explainTxt1: string;
    explainTxt2: string;
    ul1: string;
    ul2: string;
    ul3: string;
  };
};
const Footer: FunctionComponent<{ langu: FooterPropsType }> = function ({
  langu,
}) {
  const [isOpenModal, setOpenModal] = useState(false);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal} Footer={true}>
          <div
            style={{
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              boxShadow:
                "0px 4px 12px 0px rgba(0, 0, 0, 0.22),0px 4px 24px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "16px",
                backgroundColor: primaryColorTheme.primaryColor500,
                color: defaultColorTheme.White,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {window.innerWidth > 320 ? (
                <>
                  <TypographyComponent.heading.bold20>
                    {langu.modalButtonTxt.buttonTxt}
                  </TypographyComponent.heading.bold20>
                </>
              ) : (
                <>
                  <TypographyComponent.label.semiBold16>
                    {langu.modalButtonTxt.buttonTxt}
                  </TypographyComponent.label.semiBold16>
                </>
              )}
            </div>
            <div
              style={
                window.innerWidth > 320
                  ? {
                      padding: "32px 48px",
                      alignItems: "center",
                      borderBottom: "1px solid #e1e1e1",
                    }
                  : {
                      padding: "32px 32px",
                      alignItems: "center",
                      borderBottom: "1px solid #e1e1e1",
                    }
              }
            >
              {window.innerWidth > 320 ? (
                <>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular16,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {langu.modalButtonTxt.emailExplain}
                    <br />
                    <TypographyComponent.paragraphRegular.paragraphRegular16
                      style={{ color: primaryColorTheme.primaryColor500 }}
                    >
                      {langu.modalButtonTxt.email}
                    </TypographyComponent.paragraphRegular.paragraphRegular16>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TypographyComponent.paragraphRegular.paragraphRegular16
                      style={{ textAlign: "center" }}
                    >
                      {langu.modalButtonTxt.explainTxt1}
                      <br />
                      {langu.modalButtonTxt.explainTxt2}
                    </TypographyComponent.paragraphRegular.paragraphRegular16>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {langu.modalButtonTxt.emailExplain}
                    <TypographyComponent.paragraphRegular.paragraphRegular12
                      style={{ color: primaryColorTheme.primaryColor500 }}
                    >
                      {langu.modalButtonTxt.email}
                    </TypographyComponent.paragraphRegular.paragraphRegular12>
                    <TypographyComponent.paragraphRegular.paragraphRegular12></TypographyComponent.paragraphRegular.paragraphRegular12>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TypographyComponent.paragraphRegular.paragraphRegular12
                      style={{ textAlign: "center" }}
                    >
                      {langu.modalButtonTxt.explainTxt1}
                      <br />
                      {langu.modalButtonTxt.explainTxt2}
                    </TypographyComponent.paragraphRegular.paragraphRegular12>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                padding: "20px 16px",
                backgroundColor: grayScaleTheme.grayScaleColor50,
              }}
            >
              <ul
                style={
                  window.innerWidth > 320
                    ? {
                        ...TypographyStyle.paragraphRegular.paragraphRegular12,
                        color: grayScaleTheme.grayScaleColor700,
                        margin: 0,
                        padding: 0,
                        listStylePosition: "inside",
                      }
                    : {
                        ...TypographyStyle.paragraphRegular.paragraphRegular10,
                        color: grayScaleTheme.grayScaleColor700,
                        margin: 0,
                        padding: 0,
                        listStylePosition: "inside",
                      }
                }
              >
                <li>{langu.modalButtonTxt.ul1}</li>
                <li>{langu.modalButtonTxt.ul2}</li>
                <li>{langu.modalButtonTxt.ul3}</li>
              </ul>
            </div>
          </div>
        </Modal>
      )}
      <FooterWrapper>
        <div style={{ width: "100%" }}>
          <FooterImgWrapper>
            <FooterLogo src="./img/LogoWhite.png" alt="" />
            {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
              <ContactBtn
                onClick={() => {
                  setOpenModal(!isOpenModal);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Icon icon={"mdi:email-outline"} width={"16px"}></Icon>
                </div>
              </ContactBtn>
            ) : window.innerWidth <= 1024 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "225px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.linkedin.com/in/endy-choi-27718a284/"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"mingcute:linkedin-line"}
                        fontSize={24}
                        color={ContentsColor.contentSecondary}
                      />
                    </a>
                  </div>
                </div>
                <ContactBtn
                  onClick={() => {
                    setOpenModal(!isOpenModal);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Icon icon={"mdi:email-outline"} width={"24px"}></Icon>
                    <TypographyComponent.paragraphMedium.paragraphMedium16
                      style={
                        langu.modalButtonTxt.buttonTxt == "Affiliate Inquiry" ||
                        langu.modalButtonTxt.buttonTxt == "Kontaktieren Sie uns"
                          ? { fontSize: "10px" }
                          : { fontSize: 16 }
                      }
                    >
                      {langu.modalButtonTxt.buttonTxt}
                    </TypographyComponent.paragraphMedium.paragraphMedium16>
                  </div>
                </ContactBtn>
              </div>
            ) : (
              // 제일 큰 사이즈
              <>
                <Spacer space={"40px"} />
                <div
                  style={{
                    display: "flex",
                    width: "275.6px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.linkedin.com/in/endy-choi-27718a284/"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"mingcute:linkedin-line"}
                        fontSize={21}
                        color={ContentsColor.contentSecondary}
                      />
                    </a>
                  </div>
                </div>
                <div style={{ position: "absolute", right: "72px" }}>
                  <ContactBtn
                    onClick={() => {
                      setOpenModal(!isOpenModal);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Icon icon={"mdi:email-outline"} width={"24px"}></Icon>
                      <TypographyComponent.paragraphMedium.paragraphMedium16>
                        {langu.modalButtonTxt.buttonTxt}
                      </TypographyComponent.paragraphMedium.paragraphMedium16>
                    </div>
                  </ContactBtn>
                </div>
              </>
            )}
          </FooterImgWrapper>

          {/* 모바일 환경 */}
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "204px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.linkedin.com/in/endy-choi-27718a284/"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"mingcute:linkedin-line"}
                      fontSize={14}
                      color={ContentsColor.contentSecondary}
                    />
                  </a>
                </div>
              </div>
              <Spacer horizontal={true} space={"20px"} />
              <div
                style={
                  window.innerWidth >= 320 && window.innerWidth <= 768
                    ? { display: "flex", flexDirection: "column" }
                    : { display: "flex" }
                }
              >
                <div style={{}}>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    {langu.address}
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    {langu.companyName}
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    {langu.companyNumberAndCeo}
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    {langu.copyright}
                  </RegularBody02>
                </div>
              </div>
            </>
          ) : null}

          {/* 일반 디스플레이 */}
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? null : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{}}>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  {langu.address}
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  {langu.companyName}
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  {langu.companyNumberAndCeo}
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  {langu.copyright}
                </RegularBody02>
              </div>
            </div>
          )}
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
