import styled from "@emotion/styled";

export const ContainerFlexCenter = styled.div`
  justify-content: center;
  display: flex;
  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5em;
  padding-right: 5em;
`;

export const Space8em = styled.div`
  height: 8em;
`;

export const NoMarginH2 = styled.h2`
  margin-bottom: 0;

  margin-top: 0;
  font-family: Maven Pro, sans-serif;
  font-size: 2.369em;
  font-weight: 600;
  line-height: 1.2;
  &.Margin {
    margin-bottom: 1.2em;
  }
`;

export const ContentsContainer = styled.div`
  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5em;
  padding-right: 5em;
`;
