import React from "react";
import { languageType } from "types/language";
import Language from "../JsonData/Language.json";
type LangContextType = {
  lang: languageType;
  updateLang: (langType: string) => void;
};
export const LanguageContext = React.createContext<LangContextType>({
  lang: Language.language.en,
  updateLang: (langType: string) => {},
});
