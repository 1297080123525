import styled from "@emotion/styled";

export const InvestmentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 2em;

  &.scroll-text {
    transition: 1s;
    opacity: 0;
  }
  &.scrolled-text {
    transition: 1.5s;
    opacity: 1;
  }
`;

export const InvestmentContents = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;
