import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Hambuger,
  MainIcon,
  MenuItemDropDownContainer,
  MenuItemWrapper,
  MenuItemsWrapper,
  MenuWrapper,
  TopContents,
} from "../Layout/Menu.style";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Footer from "components/Layout/Footer";
import {
  SemiBoldSubHead01,
  BoldHeadLine02,
  BoldDisplay01,
} from "components/StyleComponent/FontStyle";
import {
  SidePadding20px,
  Spacer3em,
} from "components/StyleComponent/StyleComponents";
import styled from "@emotion/styled";
import {
  defaultColorTheme,
  grayScaleTheme,
  mainTheme,
} from "components/Layout/Theme";

type MenuProps = {
  className: string | undefined;
  children?: React.ReactNode;
};

const MenuContents: FunctionComponent<MenuProps> = function ({ className }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  // const [isHovered3, setIsHovered3] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const textStyle = {
    color: isHovered ? mainTheme.mainColor500 : defaultColorTheme.White,
  };
  const textStyle2 = {
    color: isHovered2 ? mainTheme.mainColor500 : defaultColorTheme.White,
  };
  // const textStyle3 = {
  //   color: isHovered3 ? mainTheme.mainColor500 : defaultColorTheme.White,
  // };

  return (
    <MenuItemsWrapper
      className={className}
      style={{
        backgroundColor: grayScaleTheme.grayScaleColor500,
        width: "100%",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <Link to={"/main"} style={{ display: "flex", textAlign: "center" }}>
          <MenuItemWrapper style={{ ...textStyle, border: "none" }}>
            <SidePadding20px>
              {window.innerWidth <= 1024 ? (
                <SemiBoldSubHead01
                  style={textStyle}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  모집공고
                </SemiBoldSubHead01>
              ) : (
                <BoldHeadLine02
                  style={textStyle}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  모집공고
                </BoldHeadLine02>
              )}
            </SidePadding20px>
          </MenuItemWrapper>
        </Link>

        <Link to={"/platform"} style={{ display: "flex", textAlign: "center" }}>
          <MenuItemWrapper style={{ border: "none" }}>
            <SidePadding20px>
              {window.innerWidth <= 1024 ? (
                <SemiBoldSubHead01 style={{ color: defaultColorTheme.White }}>
                  접수하기
                </SemiBoldSubHead01>
              ) : (
                <BoldHeadLine02
                  style={textStyle2}
                  onMouseOver={() => {
                    setIsHovered2(true);
                  }}
                  onMouseOut={() => {
                    setIsHovered2(!true);
                  }}
                >
                  접수하기
                </BoldHeadLine02>
              )}
            </SidePadding20px>
          </MenuItemWrapper>
        </Link>
      </div>
    </MenuItemsWrapper>
  );
};

const Menu: FunctionComponent = function () {
  const [open, setOpen] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);
  const [repeat, setRepeat] = useState(undefined);

  useEffect(() => {
    if (open) {
      clearTimeout(repeat);
      setRepeat(undefined);
      setOpenAnimation(true);
    } else {
      setTimeout(() => {
        setOpenAnimation(false);
        setRepeat(undefined);
      }, 400);
    }
  }, [open, repeat]);

  return (
    <MenuWrapper>
      <TopContents>
        <Link to={"/main"}>
          <MainIcon src="./img/Logo.png" />
        </Link>
        <DisplayNone1024>
          <BoldDisplay01>유성구 안전 프로젝트 참가자 모집</BoldDisplay01>
        </DisplayNone1024>
        <Hambuger onClick={() => setOpen(!open)}>
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <Icon icon="material-symbols:menu" width={24} />
          ) : (
            <Icon icon="material-symbols:menu" width={52} />
          )}
        </Hambuger>
      </TopContents>

      <MenuItemDropDownContainer
        className={open ? "slide-fade-in-dropdown" : "slide-fade-out-dropdown"}
      >
        {openAnimation && (
          <>
            <MenuContents className={undefined} />
          </>
        )}
      </MenuItemDropDownContainer>
    </MenuWrapper>
  );
};

const Challenge: FunctionComponent<{ posterImgSrc: string }> = function (
  props
) {
  return (
    <>
      <Menu />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MenuContents className={"max-width-page"} />
        <Spacer3em />
        <img src={props.posterImgSrc} alt="" />
        <Spacer3em />
      </div>
      <Footer />
    </>
  );
};
const DisplayNone1024 = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export default Challenge;
