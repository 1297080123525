import Footer from "components/Layout/Footer";
import {
  LeftIconTypo,
  MainIcon,
  MenuWrapper,
  TopContents,
} from "components/Layout/Menu.style";
import { grayScaleTheme } from "components/Layout/Theme";
import {
  BoldDisplay05,
  BoldHeadLine02,
} from "components/StyleComponent/FontStyle";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const Terms: FunctionComponent = function () {
  return (
    <>
      <MenuWrapper>
        <TopContents>
          <Link to={"/main"}>
            <MainIcon src="./img/Logo.png" />
          </Link>
          <LeftIconTypo>이용약관</LeftIconTypo>
        </TopContents>
      </MenuWrapper>
      {/* 컨텐츠 */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={
            window.innerWidth >= 320 && window.innerWidth <= 768
              ? {
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                  marginBottom: "52px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
              : {
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "100px",
                  marginBottom: "100px",
                  borderTop: "1px solid",
                  borderColor: grayScaleTheme.grayScaleColor400,
                }
          }
        >
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <BoldHeadLine02>마이렌 이용약관</BoldHeadLine02>
          ) : (
            <BoldDisplay05>마이렌 이용약관</BoldDisplay05>
          )}
        </div>
        {/* 개인정보 처리방침 내용 */}
        <div
          style={window.innerWidth >= 320 && window.innerWidth <= 768?{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "24px",
            paddingRight: "24px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }:{
            width: "76%",
            height: "800px",
            overflowY: "scroll",
            // display: "flex",
            // justifyContent: "center",
            paddingTop: "56px",
            paddingLeft: "72px",
            paddingRight: "72px",
            backgroundColor: grayScaleTheme.grayScaleColor50,
            marginBottom: "100px",
          }}
        >
          (필수) 마이렌 이용약관 동의
          <br />
          <br />
          마이렌 이용약관
          <br />
          <br />
          제1장 총칙
          <br />
          <br />
          <br />
          <br />
          제1조 목적
          <br />
          <br />
          이 약관은 주식회사 마이렌(이하 "회사"라 한다)가 운영하는 "마이렌
          어플리케이션" 통하여 회사가 제공하는 실증 서비스를 이용함에 있어
          이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
          합니다.
          <br />
          <br />
          <br />
          <br />
          제2조 정의
          <br />
          <br />
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          <br />
          (1) "서비스"란 회사가 이용자의 정보, 이용자의 단말(이하 “단말”) 자체
          센서를 기반으로 사고 발생 시, 편의 및 안전을 보조하는 위험정보
          제공(알림) 서비스(차량 충돌, 자전거 충돌, 휠체어 충돌, 보행자 충돌 및
          낙상)를 제공할 수 있는 스마트폰 어플리케이션 서비스를 칭합니다.
          <br />
          <br />
          (2) "어플리케이션"이라 함은, 서비스를 이용하기 위하여 모바일 기기를
          통해 다운로드 받거나 설치하여 사용하는 프로그램 일체 및 "스토어"에서
          무료로 다운로드 받을 수 있는 각종 어플리케이션을 의미하는데, 여기에는
          프로그램, 소프트웨어, 기타 콘텐츠 등이 포함될 수 있습니다.
          <br />
          <br />
          (3) "스토어"라 함은 어플리케이션 온라인 마켓으로서, 서비스
          어플리케이션을 무료로 다운로드 후 이용자 단말에 설치하여 사용할 수
          있도록 지원하는 전용 마켓을 말합니다.
          <br />
          <br />
          (4) “이용자 단말”이라 함은, 컴퓨터, 노트북, 스마트폰, 태블릿PC,
          스마트TV 등 인터넷 연결이 가능하여 서비스를 제공받고, 인터넷 상의
          콘텐츠를 검색, 설치, 이용 또는 보관할 수 있는 일체의 제품을 말합니다.
          <br />
          <br />
          (5) "이용자"라 함은 어플리케이션 또는 인터넷 기반 제품에 접속하여 이
          약관에 따라 회사가 제공하는 서비스를 받는 회원을 말합니다.
          <br />
          <br />
          (6) "회원"이라 함은 어플리케이션에 개인정보를 제공하여 회원등록을 한
          자로서 계정 회원에 해당하며, 사이트 및 어플리케이션 정보를 지속적으로
          제공받으며, 어플리케이션 및 인터넷 기반 제품을 통하여 회사가 제공하는
          서비스를 계속적으로 이용할 수 있는 자를 말합니다.
          <br />
          <br />
          (7) "계정"이라 함은 회원의 식별과 서비스 이용을 위한 고유식별 아이디를
          말합니다.
          <br />
          <br />
          (8) "비밀번호"라 함은 회원이 부여 받은 아이디와 일치되는 회원임을
          확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로
          관리하는 문자, 숫자 또는 특수문자의 조합을 말합니다.
          <br />
          <br />
          (9) "콘텐츠"란 본 서비스를 이용하는 과정에서 유통되는 문서, 파일,
          사진, 동영상 기타 일체의 정보를 말합니다.
          <br />
          <br />
          (10) “서비스 서버”라 함은 이용자 단말간의 연결을 도와주고 서비스를
          제공하기 위한 서버를 말합니다.
          <br />
          <br />
          제3조 약관의 효력과 변경
          <br />
          <br />
          (1) 이 약관은 이용자가 약관에 동의함으로써 효력이 발생합니다. "회사"는
          이 약관의 내용을 "이용자"가 쉽게 알 수 있도록 회원 가입시 및
          어플리케이션 내 별도의 페이지에 게시합니다.
          <br />
          <br />
          (2) 회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법,
          정보통신망이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”),
          소비자 기본법, 전자상거래 등에서의 소비자보호에 관한 법률 등
          관련법령을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 이
          경우에는 적용일자 및 개정사유, 변경되는 내용을 명시하여 현행 약관과
          함께 서비스 어플리케이션, SMS 및 이메일 등을 통해 그 적용일자 10일
          전부터 적용일자 전일까지 공지합니다. 다만, "이용자"의 권리, 의무에
          중대한 영향을 주는 개정의 경우에는 최소한 30일 이상의 사전 유예기간을
          두고 공지합니다.
          <br />
          <br />
          (3) 제2항에 의거, 변경된 약관은 별도의 동의를 받을 수 있도록
          어플리케이션 내 별도의 페이지를 제공합니다.
          <br />
          <br />
          (4) "이용자"는 변경된 약관에 대하여 거부할 권리가 있습니다. "이용자"가
          개정약관의 적용에 동의하지 않는 경우 "회사"는 개정약관의 내용을 적용할
          수 없으며, 이 경우 "이용자"는 이용계약을 해지할 수 있습니다. 다만,
          기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 "회사"는
          이용계약을 해지할 수 있습니다.
          <br />
          <br />
          <br />
          <br />
          제4조 약관 외 준칙
          <br />
          <br />
          이 약관에 명시되지 않은 사항에 대하여는 관계 법령 또는 상관례에
          따릅니다.
          <br />
          <br />
          제2장 회원가입 및 서비스 이용
          <br />
          <br />
          <br />
          <br />
          제5조 서비스 이용 계약의 성립
          <br />
          <br />
          (1) 서비스 이용 계약은 이용자가 이 약관에 동의한다는 의사표시와 함께
          회원 가입을 위한 서비스 이용 신청을 하고, 회사에서 제공하는 프로모션
          코드를 입력하면 이에 대한 회사의 이용 승낙으로 성립됩니다.
          <br />
          <br />
          (2) 회사는 이용자의 개인 정보(이용자가 만 14세 미만의 아동인 경우 그
          법정대리인의 개인정보를 포함함, 이하 같음)를 수집 및 이용함에 있어
          개인정보보호법 등 관련 법령 및 서비스의 개인정보 처리방침을 준수하고
          있으며, 이용자가 회원으로 가입하여 어플리케이션 통하여 회사가 제공하는
          서비스를 이용하고자 하는 경우, 이용자는 회사에서 요청하는 개인 정보를
          제공해야 합니다.
          <br />
          <br />
          (3) 이용자의 서비스 이용신청에 대하여 회사가 승낙한 경우, 회사는 회원
          계정과 기타 서비스 이용에 필요하다고 인정하는 내용을 이용자에게
          통지합니다.
          <br />
          <br />
          (4) 회사는 다음 각 호에 해당하는 서비스 이용 신청에 대하여는 이를
          승낙하지 아니합니다.
          <br />
          <br />
          가. 다른 사람의 명의를 사용하거나, 본인의 실명으로 신청하지 않았을 때
          <br />
          <br />
          나. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우
          <br />
          <br />
          다. 서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때
          <br />
          <br />
          라. 법령위반 또는 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로
          신청하였을 때
          <br />
          <br />
          마. 부정한 용도로 서비스를 이용하고자 하는 경우
          <br />
          <br />
          바. 규정된 제반사항을 위반하거나 기타 이용신청자의 귀책사유로
          이용승낙이 곤란한 경우
          <br />
          <br />
          사. 이용약관 위반으로 회원 자격을 박탈당하고 재가입하는 경우
          <br />
          <br />
          아. 미성년자 법정대리인의 동의 여부가 확인되지 않는 경우
          <br />
          <br />
          자. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
          <br />
          <br />
          <br />
          <br />
          제6조 서비스 이용 및 제한
          <br />
          <br />
          (1) 회원은 어플리케이션을 통해 발급된 계정으로 본 약관에 따라 서비스
          이용이 가능합니다.
          <br />
          <br />
          (2) 서비스는 어플리케이션 그리고 회사의 인터넷 기반 제품의 업무상 또는
          기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 제공됨을 원칙으로
          합니다.
          <br />
          <br />
          (3) 회사는 다음과 같은 사유가 있는 경우에는 언제든지 서비스의 전부
          또는 일부를 중단하거나 종료할 수 있습니다.
          <br />
          <br />
          　　가. 정보통신 시스템 등의 정기점검, 보수, 교체, 고장, 통신두절 등의
          사유가 발생한 경우.
          <br />
          <br />
          　　나. 회사가 서비스의 운영 또는 개선을 위하거나 또는 법령상 의무
          준수를 위한 경우
          <br />
          <br />
          다. 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비
          보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우
          <br />
          <br />
          　　라. 기타 천재지변, 국가 비상사태 등 불가항력 사유가 있는 경우
          <br />
          <br />
          (4) 제3항에 의한 서비스 중단의 경우에는 회사는 회원에게 제15조의
          방법으로 통지를 합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스
          중단으로 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
          <br />
          <br />
          (5) 서비스는 실증 사용자에 한해 기본적으로 무료로 제공되며 타 단말에
          서비스 단말기의 위치정보 공유를 통하여 서비스가 가능합니다.
          <br />
          <br />
          (6) 회사가 제공하는 저작물에 대한 저작권, 기타 지적 재산권은 회사에
          귀속합니다.
          <br />
          <br />
          (7) 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이
          복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로
          이용하거나 제3자에게 이용하게 하여서는 안됩니다.
          <br />
          <br />
          (8) 회사는 서비스 요금이 무료인 서비스 이용과 관련하여 이용자에게
          발생한 손해에 대해서 책임을 지지 않습니다.
          <br />
          <br />
          (9) 회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다.
          다만, 이러한 경우에는 그 내용을 이용자에게 사전에 공지합니다. 단, 사전
          통지가 가능하지 않다고 판단되는 합리적인 사유가 있거나, 이용자에게
          편익을 주는 서비스에 새로운 기능을 추가하는 변경 또는 법적인 이유로
          시행되는 변경은 사전 통지 없이 즉시 적용될 수 있습니다.
          <br />
          <br />
          (10) 회사는 관련 법령에 특별한 규정이 없는 한 서비스의 전부 또는
          일부의 변경, 중단 또는 종료를 사유로 별도의 보상을 하지 않습니다
          <br />
          <br />
          <br />
          제3장 의무
          <br />
          <br />
          <br />
          <br />
          제7조 회사의 의무
          <br />
          <br />
          (1) 회사는 특별한 사정이 없는 한 회원이 신청한 서비스에 대한
          이용계약이 성립된 날에 서비스를 이용 할 수 있도록 합니다.
          <br />
          <br />
          (2) 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서는
          적절한 절차를 거쳐 처리하며, 처리시 일정 기간이 소요될 경우 회원에게
          그 사유와 처리 일정을 알려주어야 합니다.
          <br />
          <br />
          (3) 회사는 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를
          운영하거나 개선하기 위한 경우 또는 제품소개 등 내부 목적으로 이용하는
          데만 사용하고, 회원의 동의가 없는 한 상기 이외의 다른 목적으로 타 기관
          및 제3자 에게 양도하지 않습니다. 개인정보의 보호 및 사용에 대하여는
          관련법 및 "회사"의 개인정보 처리방침이 적용됩니다. 다만, "회사"의 공식
          사이트 이외의 링크된 사이트에서는 "회사"의 개인정보 처리방침이
          적용되지 않습니다.
          <br />
          <br />
          <br />
          <br />
          제8조 회원의 의무
          <br />
          <br />
          (1) 회원은 회원의 계정과 비밀번호에 관한 관리의 책임을 부담하며,
          회원의 귀책사유로 인한 계정과 비밀번호 유출로 인한 손해에 대하여
          회사는 책임을 지지 않습니다.
          <br />
          <br />
          (2) 회원은 계정 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다. 단,
          회원이 가족 구성원 등 제3자에게 계정과 비밀번호를 공유하여 '서비스'를
          함께 이용하는 경우, 회원으로부터 계정과 비밀번호를 공유 받은 가족
          구성원 등 제3자는 회원의 관리 하에 '서비스'에 대한 접근 권한을 부여
          받은 것이 되며 이로 인한 불이익이나 손해에 대하여 회사는 책임을
          부담하지 않습니다. 회원은 계정 및 비밀번호를 도난 당하거나 제3자가
          사용하고 있음을 인지하는 경우에는 반드시 회사에 그 사실을 통보해야
          합니다. 회원이 위와 같은 사실을 회사에 통지하지 않거나 통지한 경우에도
          회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임을
          부담하지 않습니다.
          <br />
          <br />
          (3) “서비스”는 “회원”의 편의를 위하여 제공됩니다. 따라서, “회원”은
          도난이나 사고 등 안전사고를 방지하기 위한 일반적인 주의의무가 있으며,
          “회원”의 귀책사유로 인해 사고가 발생할 경우 발생하는 손해에 대한 모든
          책임은 “회원”에게 있습니다.
          <br />
          <br />
          (4) 회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.
          <br />
          <br />
          (5) 회원을 포함한 이용자는 다음 행위를 하여서는 안됩니다.
          <br />
          <br />
          가. 신청 또는 변경 시 허위내용 또는 타인의 정보의 등록
          <br />
          <br />
          나. 어플리케이션에 게시된 정보의 변경
          <br />
          <br />
          다. 회사에 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
          <br />
          <br />
          라. 회사 또는 기타 제3자의 저작권 등 지적재산권에 대한 침해
          <br />
          <br />
          마. 회사 또는 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          <br />
          <br />
          바. 외설 또는 폭력적인 메시지, 화상, 음성 기타 미풍양속에 반하는
          정보를 공개 또는 게시하는 행위
          <br />
          <br />
          사. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지
          않는 행위
          <br />
          <br />
          아. 회사가 게시한 정보의 변경 또는 서비스에 장애를 주는 행위
          <br />
          <br />
          자. 다른 회원의 개인정보 및 계정보를 무단으로 수집·저장·게시·유포하는
          행위
          <br />
          <br />
          차. 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를
          통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위
          <br />
          <br />
          카. 본인 아닌 제3자에게 계정을 대여, 양도하는 등 접속권한을 부여하는
          행위
          <br />
          <br />
          타. 도박 등 사해행위를 하거나 유도하는 행위, 음란 사이트를
          연결(링크)하는 행위, 수치심·혐오감 또는 공포심을 일으키는
          말·소리·글·그림·사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등
          서비스를 불건전하게 이용하는 행위
          <br />
          <br />
          파. 게시판을 도배할 목적에서 동일 또는 유사한 게시글 또는 의미가 없는
          게시글을 반복적으로 게시하는 행위
          <br />
          <br />
          하. 회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등
          본래의 용도 이외의 용도로 서비스를 이용하는 행위
          <br />
          <br />
          거. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제·유통·조장하거나
          상업적으로 잉요하는 행위, 알려지거나 알려지지 않은 버그를 악용하여
          서비스를 이용하는 행위
          <br />
          <br />
          너, 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과
          관련하여 타인에게 피해를 입히는 행위
          <br />
          <br />
          더. 기타 불법적이거나 부당한 수단과 목적에 의한 행위
          <br />
          <br />
          <br />
          제4장 서비스 이용 계약해지 및 이용중지
          <br />
          <br />
          <br />
          <br />
          제9조 서비스 이용 계약해지 및 이용중지
          <br />
          <br />
          (1) 회원이 서비스 이용계약을 해지하고자 하는 때에는 회원 본인이 직접
          어플리케이션에서 서비스 이용 계약해지를 신청해야 합니다.
          <br />
          <br />
          (2) 전항의 경우, 회원은 계정, 비밀번호를 입력하여 본인임을 확인한 후,
          해지 확인을 선택하면 자동으로 회원가입 및 서비스 이용계약이
          해지됩니다.
          <br />
          <br />
          (3) 회원 가입 및 서비스 이용계약의 해지 여부는 계정 회원의 경우 기존
          계정, 비밀번호로 로그인이 되지 않으면 해지된 것입니다.
          <br />
          <br />
          (4) 회사는 회원이 어플리케이션 또는 서비스 이용 과정에서 다음 사항에
          해당하는 행위를 하였을 경우, 상당한 기간을 정하여 서비스 이용을 중지할
          수 있습니다.
          <br />
          <br />
          가. 공공 질서 및 미풍양속에 반하는 경우
          <br />
          <br />
          나. 범죄적 행위에 관련되는 경우
          <br />
          <br />
          다. 국가 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는
          실행할 경우
          <br />
          <br />
          라. 타인의 계정 및 비밀번호를 도용한 경우
          <br />
          <br />
          마. 회사 또는 타인의 명예를 손상시키거나 불이익을 주는 경우
          <br />
          <br />
          바. 같은 사용자가 다른 계정으로 이중 등록을 한 경우
          <br />
          <br />
          사. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우
          <br />
          <br />
          아. 기타 관련 법령이나 어플리케이션이 정한 이용조건에 위배되는 경우
          <br />
          <br />
          (5) 회사는 회원이 다음 사항에 해당하는 행위를 하였을 경우, 사전 통지
          없이 계약을 해지할 수 있습니다. 본 항에 따른 계약해지 시 서비스 이용을
          통해 획득한 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지
          않습니다.
          <br />
          <br />
          가. 이용신청 또는 변경시 허위내용을 등록한 경우
          <br />
          <br />
          나. 서비스 운영을 고의 또는 중과실로 방해하여 회사에 손해를 입힌 경우
          <br />
          <br />
          다. "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포 등과
          같이 관련법을 위반한 경우
          <br />
          <br />
          라. 전항에 규정된 사유로 서비스 이용이 중지된 회원이 그 이용중지 기간
          내에 이용중지 사유를 해소하지 아니하는 경우
          <br />
          <br />
          <br />
          <br />
          제10조 서비스 이용중지의 해제 절차
          <br />
          <br />
          (1) 회사는 서비스 이용 중지를 하고자 하는 경우에는 그 사유, 일시 및
          기간을 정하여 서면(메일, SMS, Push 알림 등) 또는 전화 등의 방법을
          이용하여 해당 회원 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게
          이용을 중지해야 할 필요가 있다고 인정하는 경우에는 서비스 이용을
          중지한 후 사후 통지 할 수 있습니다.
          <br />
          <br />
          (2) 전항의 규정에 의하여 서비스 이용중지를 통지 받은 회원 또는 그
          대리인은 서비스 이용중지에 대하여 이의 신청을 할 수 있습니다.
          <br />
          <br />
          (3) 회사는 서비스 이용중지 기간 중에 그 이용중지 사유가 해소된 것이
          확인 된 경우나 회원 또는 그 대리인의 이의 신청에 이유가 있는 경우 등에
          한하여 이용중지 조치를 즉시 해제합니다.
          <br />
          <br />
          <br />
          <br />
          제5장 일반사항
          <br />
          <br />
          <br />
          <br />
          제11조 이용자의 게시물 관리
          <br />
          <br />
          회사는 이용자가 게시하거나 등록한 내용물이 다음 사항에 해당된다고
          판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.
          <br />
          <br />
          (1) 스팸(Spam)성 게시물(예 : 행운의 편지, 돈버는 메일 등)
          <br />
          <br />
          (2) 회사 또는 타인을 비방하거나 중상모략으로 개인 및 단체의 명예를
          손상시키는 내용인 경우
          <br />
          <br />
          (3) 공공질서 및 미풍양속에 위반되는 내용인 경우
          <br />
          <br />
          (4) 범죄적 행위에 부합된다고 인정되는 내용인 경우
          <br />
          <br />
          (5) 회사 또는 타인의 저작권 등 기타의 권리를 침해하는 내용인 경우
          <br />
          <br />
          (6) 기타 관계 법령이나 어플리케이션에서 정한 규정에 위배되는 내용인
          경우
          <br />
          <br />
          <br />
          <br />
          제12조 정보의 제공 및 광고의 게재
          <br />
          <br />
          (1) 회사는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를
          공지사항이나 전자우편 등의 방법으로 이용자에게 제공할 수 있습니다.
          다만, 이용자는 관련법에 따른 거래관련 정보, 고객센터 답변 등을
          제외하고는 언제든지 위 정보제공에 대해서 수신을 거절할 수 있습니다.
          <br />
          <br />
          (2) 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편
          등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한
          이용자는 수신거절을 회사에게 할 수 있습니다.
          <br />
          <br />
          <br />
          <br />
          제13조 손해배상
          <br />
          <br />
          회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게
          손해를 끼친 경우에는 이를 배상하여야 합니다. 단, 회사는 무료로
          제공하는 서비스의 이용과 관련하여 회사는 책임을 지지 않습니다.
          <br />
          <br />
          <br />
          <br />
          제14조 면책조항
          <br />
          <br />
          (1) 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
          <br />
          <br />
          (2) 회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을
          지지 않습니다.
          <br />
          <br />
          (3) 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나
          서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
          <br />
          <br />
          (4) 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성
          등 내용에 관하여는 책임을 지지 않습니다.
          <br />
          <br />
          (5) 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
          규정이 없는 한 책임을 지지 않습니다.
          <br />
          <br />
          (6) 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데
          이용자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.
          <br />
          <br />
          (7) 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한
          사유로 발생한 손해에 대한 책임이 면제됩니다.
          <br />
          <br />
          (8) 회사는 회원의 컴퓨터 및 스마트 기기 오류에 의해 손해가 발생한
          경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가
          발생한 경우 책임을 지지 않습니다.
          <br />
          <br />
          (9) 이용자가 본 서비스를 이용함에 있어 행한 불법행위나 이 약관의
          위반행위로 인하여 회사가 당해 이용자 이외의 제3자로부터 손해배상청구
          또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 이용자는 자신의
          책임과 비용으로 회사를 면책시켜야 하며 회사가 면책되지 못한 경우 당해
          이용자는 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
          <br />
          <br />
          (10) 서비스와 관련하여 이용자와 제3자 간에 분쟁이 발생한 경우 회사는
          그 분쟁에 개입하지 않으며 그 분쟁의 결과에 책임이 없습니다. 이와
          관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우
          회사는 이용자에게 구상권을 행사할 수 있습니다.
          <br />
          <br />
          (11) 회사는 서비스와 관련하여 하드웨어적 특성, 통신 상태, 기타 주변
          상황 등으로 서비스의 일부가 원활하게 이루어지지 않아 발생한 손해에
          대하여 책임을 지지 않습니다.
          <br />
          <br />
          <br />
          <br />
          제15조 회원에 대한 통지 및 정보제공
          <br />
          <br />
          (1) 회사가 회원에 대한 통지를 하는 경우에는 회원이 서비스 이용 신청시
          어플리케이션을 통해 제출한 전자 우편 주소, SMS, 팝업을 이용하여 통지
          할 수 있습니다.
          <br />
          <br />
          (2) 회사는 불특정다수 회원에 대한 통지의 경우 어플리케이션 내
          게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원에게 중대한
          영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.
          <br />
          <br />
          (3) 회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에
          대해서 전자우편이나 서신, 우편, SMS, 전화, Push 메시지(별도 동의한
          경우) 등의 방법으로 회원에게 제공할 수 있습니다.
          <br />
          <br />
          (4) 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의
          동의 하에 관련 법령에 따라 추가적인 개인 정보를 수집할 수 있습니다.
          <br />
          <br />
          <br />
          <br />
          제16조 회사의 연락처
          <br />
          <br />
          "회사"의 상호 및 주소 등은 다음과 같습니다.
          <br />
          <br />
          상호: 주식회사 마이렌
          <br />
          <br />
          주소: 충청남도 천안시 서북구 천안천4길 32, 그린스타트업타운 412호
          <br />
          <br />
          대표메일: info@myren.kr
          <br />
          <br />
          사업자등록번호: 261-86-01229
          <br />
          <br />
          <br />
          <br />
          제17조 일부 무효
          <br />
          <br />
          관련 법령 등에 의하여 본 약관의 일부 규정이 무효가 될 경우, 나머지
          규정만으로 그 목적 달성이 불가능하거나 회원에게 부당하게 불리하지
          아니하는 이상 본 약관은 그 효력을 유지합니다.
          <br />
          <br />
          <br />
          <br />
          제18조 재판관할 및 준거법
          <br />
          <br />
          (1) 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의
          관계법령과 상관습에 따릅니다.
          <br />
          <br />
          (2) 회사의 유료 서비스 이용 회원의 경우 당해 서비스와 관련하여서는
          회사가 별도로 정한 약관 및 정책에 따릅니다.
          <br />
          <br />
          (3) 서비스 이용과 관련하여 회사와 이용자 간에 발생하는 분쟁과 관련한
          소송의 관할 법원은 민사소송법상의 관할을 가지는 대한민국 법원으로
          하며, 준거법은 대한민국 법으로 합니다.
          <br />
          <br />
          <br />
          <br />
          제19조 계약의 효력
          <br />
          <br />
          이 서비스와 관련하여 "회사"와 "이용자"간 체결되는 본 약관은 완전한
          계약으로 기존에 맺어진 이와 상충하는 어떠한 구두나 서면에 의한 합의,
          계약 등 보다 우선하여 적용되며, 관계법령 등에 따라 그 중 일부가 무효가
          되더라도 나머지 조항은 그대로 유효하게 적용됩니다.
          <br />
          <br />
          (부칙)
          <br />
          <br />
          1. 본 약관은 2023년 7월 10일부터 적용됩니다.
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
