import Spacer8em, {
  Spacer6em,
} from "components/StyleComponent/StyleComponents";
import {
  ContainerFlexCenter,
  NoMarginH2,
} from "components/ComponentStyle/TopBanner.style";
import { FunctionComponent } from "react";
import { IconGrid } from "../ComponentStyle/Icon.style";
import IconItem from "./IconItem";

const LoveMyRenIcon: FunctionComponent<{ title: string }> = function (props) {
  return (
    <>
      <Spacer8em />
      <ContainerFlexCenter>
        <NoMarginH2 style={{ maxWidth: "20ch" }}>{props.title}</NoMarginH2>
      </ContainerFlexCenter>
      <Spacer6em />
      <ContainerFlexCenter>
        <IconGrid>
          <IconItem
            imgSrc={
              "https://assets.website-files.com/608c6d5b86372756c37c44d3/608c6d5b8637277b347c4552_UBI-Icon-1.svg"
            }
            explainText={"test"}
          />
          <IconItem
            imgSrc={
              "https://assets.website-files.com/608c6d5b86372756c37c44d3/608c6d5b8637277b347c4552_UBI-Icon-1.svg"
            }
            explainText={"test"}
          />
          <IconItem
            imgSrc={
              "https://assets.website-files.com/608c6d5b86372756c37c44d3/608c6d5b8637277b347c4552_UBI-Icon-1.svg"
            }
            explainText={"test"}
          />
          <IconItem
            imgSrc={
              "https://assets.website-files.com/608c6d5b86372756c37c44d3/608c6d5b8637277b347c4552_UBI-Icon-1.svg"
            }
            explainText={"test"}
          />
          <IconItem
            imgSrc={
              "https://assets.website-files.com/608c6d5b86372756c37c44d3/608c6d5b8637277b347c4552_UBI-Icon-1.svg"
            }
            explainText={"test"}
          />
        </IconGrid>
      </ContainerFlexCenter>
      <Spacer8em />
    </>
  );
};

export default LoveMyRenIcon;
