import styled from "@emotion/styled";

export const BlogItemLinkWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: auto;
  bottom: 1.5em;
  left: 1.5em;
  right: auto;
`;

export const BlogItemLinkTag = styled.a`
  text-transform: uppercase;
  background-color: #00cc9b;
  border-radius: 0.2em;
  padding: 0.2em 0.5em;
  text-decoration: none;
  position: relative;

  max-width: 100%;
  display: inline-block;
`;

export const BlogItemLinkSmallText = styled.div`
  font-size: 0.8em;
`;

export const BlogItemLinkA = styled.a`
  position: relative;
  background-color: #0e0e0e;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BlogItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

  border: 0;
`;

export const BlogItemImgIndex = styled.div`
  background-color: #0f0f0f;
  z-index: 10;
  position: absolute;
  top: auto;
  bottom: 1.5em;
  left: 1.5em;
  right: auto;
`;

export const BlogItemTextA = styled.a`
  border-bottom: 2px solid transparent;
  font-family: Maven Pro, sans-serif;
  font-size: 1.333em;
  font-weight: 600;
  line-height: 1.3;
  text-decoration: none;
  transition: all 0.2s;

  color: #151d45;
`;

export const BlogItemTextWrapper = styled.div`
  padding-left: 4.86em;
  padding-right: 4.86em;
`;
