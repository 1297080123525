import {
  TypographyStyle,
  TypographyComponent,
} from "components/StyleComponent/FontStyle";
import { DefaultTheme } from "styled-components";

export const defaultColorTheme = {
  Black: "#000000",
  White: "#ffffff",
  Gray: "#808080",
};

export const grayScaleTheme = {
  grayScaleColor50: "#F6F6F6",
  grayScaleColor100: "#F3F4F6",
  grayScaleColor200: "#DADADA",
  grayScaleColor300: "#C8C8C8",
  grayScaleColor400: "#9CA3AF",
  grayScaleColor500: "#6B7280",
  grayScaleColor600: "#4B5563",
  grayScaleColor700: "#525252",
  grayScaleColor800: "#292929",
  grayScaleColor900: "#101010",
};

export const ContentsColor = {
  contentPrimary: grayScaleTheme.grayScaleColor800,
  contentSecondary: grayScaleTheme.grayScaleColor600,
  contentTertiary: grayScaleTheme.grayScaleColor300,
};

export const mainTheme = {
  mainColor50: "#FEF1EC",
  mainColor100: "#FCE1D8",
  mainColor200: "#F9C3B1",
  mainColor300: "#F6A68A",
  mainColor400: "#F38863",
  mainColor500: "#F06A3C",
  mainColor600: "#B4502D",
  mainColor700: "#78351E",
  mainColor800: "#3C1B0F",
  mainColor900: "#180B06",
};

export const primaryColorTheme = {
  primaryColor50: "#fff1ec",
  primaryColor100: "#fee2d9",
  primaryColor200: "#fdc5b2",
  primaryColor300: "#fca98c",
  primaryColor400: "#fb8c65",
  primaryColor500: "#fa6f3f",
  primaryColor600: "#bc532f",
  primaryColor700: "#7d3820",
  primaryColor800: "#3f1c10",
  primaryColor900: "#190b06",
};

export const subTheme: DefaultTheme = {
  subColor50: "#EDF0FE",
  subColor100: "#DADFFC",
  subColor200: "#B5BFF9",
  subColor300: "#909FF6",
  subColor400: "#6B7FF3",
  subColor500: "#465FF0",
  subColor600: "#3547B4",
  subColor700: "#233078",
  subColor800: "#12183C",
  subColor900: "#070A18",
};

export const successTheme: DefaultTheme = {
  successColor50: "#EEF8E9",
  successColor100: "#DCF0D2",
  successColor200: "#B9E1A5",
  successColor300: "#95D279",
  successColor400: "#72C34C",
  successColor500: "#4FB41F",
  successColor600: "#3B8717",
  successColor700: "#285A10",
  successColor800: "#142D08",
  successColor900: "#081203",
};

export const errorTheme: DefaultTheme = {
  errorColor50: "#FEEEEC",
  errorColor100: "#FBDCD8",
  errorColor200: "#F7B8B1",
  errorColor300: "#F3958B",
  errorColor400: "#EF7164",
  errorColor500: "#EB4E3D",
  errorColor600: "#B03B2E",
  errorColor700: "#76271F",
  errorColor800: "#3B140F",
  errorColor900: "#180806",
};

export const warningTheme: DefaultTheme = {
  warningColor50: "#FEF9E8",
  warningColor100: "#FCF2D0",
  warningColor200: "#F9E5A1",
  warningColor300: "#F5D871",
  warningColor400: "#F2CB42",
  warningColor500: "#EFBE13",
  warningColor600: "#B38F0E",
  warningColor700: "#785F0A",
  warningColor800: "#3C3005",
  warningColor900: "#181302",
};

export const ButtonStyle = {
  Enabled: {
    ...TypographyStyle.paragraphRegular.paragraphRegular12,
    padding: "4px 12px",
    backgroundColor: defaultColorTheme.White,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: mainTheme.mainColor500,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: mainTheme.mainColor500,
  },
  EnabledChecked: {
    ...TypographyStyle.paragraphRegular.paragraphRegular12,
    padding: "4px 12px",
    backgroundColor: primaryColorTheme.primaryColor500,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: primaryColorTheme.primaryColor500,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: defaultColorTheme.White,
  },
  Disabled: {
    padding: "4px 12px",
    backgroundColor: "white",
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: grayScaleTheme.grayScaleColor200,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: grayScaleTheme.grayScaleColor200,
  },
};
export const ShadowStyle = {
  _2: {
    boxShadow: "0 2 2 #0000001f",
    value: {
      color: "#0000001f",
      type: "dropShadow",
      x: "0",
      y: "2",
      blur: "2",
      spread: "0",
    },
  },
  _4: {
    value: [
      {
        color: "#00000021",
        type: "dropShadow",
        x: "0",
        y: "2",
        blur: "4",
        spread: "0",
      },
      {
        color: "#0000000f",
        type: "dropShadow",
        x: "0",
        y: "0",
        blur: "1",
        spread: "0",
      },
    ],
    type: "boxShadow",
  },
};
