import Footer from "components/Layout/Footer";
import TopIntro from "components/SecondContents/TopIntro";
import ThirdContents from "components/ThirdContents/ThirdContents";
import { FunctionComponent, useEffect, useState } from "react";
import Menu from "components/Layout/Menu";
import { CountUserLabel } from "../components/Main/CountUserLabel";
import { off, onValue, ref } from "@firebase/database";
import { database } from "FirebaseSetting";

const Main: FunctionComponent = function () {
  return (
    <>
      <Menu />
      <TopIntro />
      <ThirdContents />
      <CountUserLabel />
      <Footer />
    </>
  );
};

export default Main;
