import styled from "@emotion/styled";
import { grayScaleTheme } from "components/Layout/Theme";

export const SliderWarp = styled.div`
  justify-content: center;
  align-items: center;
  background-color: ${grayScaleTheme.grayScaleColor50};
  padding: 50px 0;
  display: flex;
`;
export const SliderLogoWarp = styled.div`
  //width: 50%;
`;

export const SliderTextWarp = styled.div`
  text-align: right;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5em;
  display: flex;
`;

export const SliderTextDiv = styled.div`
  z-index: 10;
  object-fit: fill;
  overflow: visible;

  position: relative;
`;

export const SliderText = styled.h3`
  font-family: Maven Pro, sans-serif;
  font-size: 1.777em;
  font-weight: 600;
  line-height: 1.3;

  //color: #fff;
`;
