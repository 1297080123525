import React, { CSSProperties, FunctionComponent, ReactElement } from "react";
import { FlexCenter, GridItem } from "./LayoutStyle";
import { grayScaleTheme } from "components/Layout/Theme";
import { TypographyStyle } from "./FontStyle";
import { Spacer } from "./Spacer";

const VerticalNameTagRadiusBox: FunctionComponent<{
  children: ReactElement | ReactElement[] | string;
  wordBreak?: boolean;
  LabelText: string;
  style?: CSSProperties | undefined;
}> = function (props) {
  return (
    <GridItem>
      <div
        style={{
          width: "fit-content",
          borderRadius: "8px",
          padding: "4px 8px",
          backgroundColor: grayScaleTheme.grayScaleColor50,
          ...TypographyStyle.paragraphMedium.paragraphMedium12,
          border: "1px solid",
          borderColor: grayScaleTheme.grayScaleColor200,
        }}
      >
        {props.LabelText}
      </div>
      <Spacer horizontal={true} space={"8px"} />
      <FlexCenter
        style={{
          display: "flex",
          //   height: "100%",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          wordBreak: props.wordBreak ? "keep-all" : "break-all",
          ...TypographyStyle.paragraphMedium.paragraphMedium12,
        }}
      >
        {props.children}
      </FlexCenter>
    </GridItem>
  );
};

export default VerticalNameTagRadiusBox;
