import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  display: block;
`;

export const Contents = styled.div`
  z-index: 10;
  height: 11.81em;
  max-width: 1919px;
  //background-color: #151d45;
  //border-radius: 20px;
  justify-content: space-between;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding-right: 3em;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const ColumnCenter = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
`;

export const FlexRowCenterDiv = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ContainerH4Div = styled.div`
  font-family: Maven Pro, sans-serif;
  font-size: 1.333rem;
  font-weight: 600;
  line-height: 1.3;
  &._10ch {
    max-width: 10ch;
  }
`;

export const GridColumn2 = styled.div`
  grid-column-gap: 11.81rem;
  grid-row-gap: 6.94rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
`;

export const GridColumn3 = styled.div`
  grid-column-gap: 10rem;
  grid-row-gap: 5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  display: grid;
`;

export const GridRow1 = styled.div`
  grid-area: span 1 / span 2 / span 1 / span 2;
`;

export const GridRow21 = styled.div`
  grid-area: 2/2/3/4;
`;
export const GridRow11 = styled.div`
  grid-area: 1/1/2/3;
`;

export const GridItemOdd = styled.div`
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  display: grid;
`;

export const NoMarginCenterH1 = styled.h1`
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  font-family: Maven Pro, sans-serif;
  font-size: 3.157rem;
  font-weight: 600;
  line-height: 1.1;
`;

export const BgVideo = styled.video`
  width: 100%;
  height: 100%;
  //object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`;
