import { ColumnCenter, Container } from "components/RootComponent.style";
import Spacer8em, {
  ContainerH1,
  ContainerH4,
  Spacer1em,
  Spacer2em,
  Spacer6em,
} from "components/StyleComponent/StyleComponents";
import { FunctionComponent } from "react";
import { MainIntroCardContainer } from "../ComponentStyle/MainIntroCard.style";

type CardContents = {
  title: string | null;
  subTitle: string | null;
  explainText: string | null;
};
const MainIntroCard: FunctionComponent<CardContents> = function (
  props: CardContents
) {
  return (
    <>
      <Container style={{ position: "static", flex: 1 }}>
        <Spacer6em />
        <MainIntroCardContainer>
          <ColumnCenter>
            <ContainerH4 contents={props.subTitle} />
            <Spacer1em />
            <ContainerH1 contents={props.title} />
            <Spacer2em />
            <p style={{ maxWidth: "45ch" }}>{props.explainText}</p>
            <Spacer8em />
          </ColumnCenter>
        </MainIntroCardContainer>
      </Container>
    </>
  );
};

export default MainIntroCard;
