import { FunctionComponent, ReactElement } from "react";
import { ContainerFlexCenter } from "../ComponentStyle/TopBanner.style";
import Spacer8em, {
  Spacer3_25em,
} from "components/StyleComponent/StyleComponents";
import {
  BoldDisplay05,
  BoldHeadLine02,
} from "components/StyleComponent/FontStyle";

type BannerPlusContents = {
  h2Contents: String | null;
  h2ContentsMarginBottom: boolean | null;
  plusContents: String | null;
  plusContents2: String | null;
  children: ReactElement | null;
};

const TopBanner: FunctionComponent<BannerPlusContents> = function ({
  h2Contents,
  children,
  h2ContentsMarginBottom,
}) {
  return (
    <>
      <ContainerFlexCenter>
        <div style={{ textAlign: "center" }}>
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <Spacer3_25em />
          ) : (
            <Spacer8em />
          )}
          {/* <NoMarginH2></NoMarginH2> */}
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <BoldHeadLine02>{h2Contents}</BoldHeadLine02>
          ) : (
            <BoldDisplay05>{h2Contents}</BoldDisplay05>
          )}
          {/* <div style={{height: "12.06rem" }}/> */}
          {/* <Spacer8em />
          {h2Contents || plusContents || plusContents2 ? (
            <ContentsContainer>
              {h2ContentsMarginBottom ? (
                <NoMarginH2 className="Margin">{h2Contents}</NoMarginH2>
              ) : (
                <NoMarginH2>{h2Contents}</NoMarginH2>
              )}
              {plusContents ? (
                <>
                  <p>{plusContents}</p>
                  <br />
                  <br />
                </>
              ) : null}
              {plusContents2 ? <p>{plusContents2}</p> : null}
            </ContentsContainer>
          ) : null}
          {children} */}
        </div>
      </ContainerFlexCenter>
    </>
  );
};

export default TopBanner;
