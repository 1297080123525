import { FlexRowCenterDiv } from "components/RootComponent.style";
import { FunctionComponent } from "react";
import { LottieAniComponent } from "../AppPopup/AppPopup.style";
import { BlogItemImg } from "components/ComponentStyle/Blog.style";

const IntroAniComponent: FunctionComponent = function () {
  return (
    <FlexRowCenterDiv>
      <div style={{ marginRight: "23px" }}>
        <img src="./img/AppPopup.png" alt="" />
        <LottieAniComponent
          aniSrc={"https://assets7.lottiefiles.com/packages/lf20_blflhah3.json"}
        />
      </div>
      <div>
        <BlogItemImg src="./img/DirectSiren.png" alt="" />
      </div>
    </FlexRowCenterDiv>
  );
};

export default IntroAniComponent;
