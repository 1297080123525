// import Footer from "components/Layout/Footer";
// import SecondContents from "components/SecondContents/SecondContents";
// import ThirdContents from "components/ThirdContents/ThirdContents";
import FooterEN from "components/Layout/FooterEN";
import { FunctionComponent } from "react";
// import Menu from "components/Layout/Menu";
// import { CountUserLabel } from "../components/Main/CountUserLabel";
// import { useLocation } from "react-router-dom";

const MainEng: FunctionComponent = function () {
  return (
    <>
      <img src="./img/1_cata.png" alt="img1" />
      <img src="./img/2_cata.png" alt="img1" />
      <img src="./img/3_cata.png" alt="img1" />
      <img src="./img/4_cata.png" alt="img1" />
      <img src="./img/5_cata.png" alt="img1" />
      <img src="./img/6_cata.png" alt="img1" />
      <img src="./img/7_cata.png" alt="img1" />
      <img src="./img/8_cata.png" alt="img1" />
      <img src="./img/9_cata.png" alt="img1" />

      <FooterEN
        langu={{
          address:
            "Address. Room 412, Green Startup Town, 32, Cheonan 4-gil, Cheonan-si, Chungcheongnam-do, South Korea (Wachon-dong)",
          companyName: "Myren.Inc",
          companyNumberAndCeo:
            "Business registration number(South Korea): 261-86-01229, CEO: Choi Eun-hong",
          copyright: "© 2023 MYREN All rights reserved.",
          modalButtonTxt: {
            buttonTxt: "Affiliate Inquiry",
            email: "info@myren.kr",
            emailExplain: "Please send an inquiry about the partnership to",
            explainTxt1: "If you want a more accurate and detailed answer,",
            explainTxt2: "please contact us with the following information.",
            ul1: "Affiliation classification (corporate partnership, business partnership, marketing partnership, advertising partnership, etc.)",
            ul2: "Background of proposal / Details of proposal / Expected effect of alliance, etc",
            ul3: "Attach files (if required)",
          },
        }}
      />
    </>
  );
};

export default MainEng;
