import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "page/MainPage";
import GlobalStyle from "components/Layout/Global.style";
import Platform from "components/Platform/PlatformPage";
import Solution from "page/Solution";
import Industries from "components/Industries/Industries";
import Company from "components/Company/Company";
import Challenge from "components/Challenge/Challenge";
import { ControllServer } from "page/ControllServer";
import { Login } from "page/Login";
import ScrollAnimation from "page/Scroll_Counter";
import Policy from "page/Policy";
import LocationPolicy from "page/LocationPolicy";
import Terms from "page/Terms";
import MainEng from "page/MainPageEng";
import { off, onValue, ref } from "@firebase/database";
import { database } from "FirebaseSetting";
import { FunctionComponent, useEffect, useState } from "react";
import PolicyIOS from "page/Policy_IOS";
import PolicyANDROID from "page/Policy_android";
import DashBoard from "page/DashBoard";

function App() {
  const dbRef = ref(database, "AccidentRecord/");
  const [accidentRecordCount, setRecordCount] = useState(0);
  useEffect(() => {
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setRecordCount(Object.keys(data).length);
        window.localStorage.setItem(
          "dataLen",
          Object.keys(data).length.toString()
        );
      }
    });
    return () => {
      off(dbRef, "value");
    };
  }, []);
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/en" element={<MainEng />} />
          <Route path="/login" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/company" element={<Company />} />
          <Route
            path="/challenge"
            element={<Challenge posterImgSrc={"./img/Challenge_main.png"} />}
          />
          <Route path="/test" element={<ScrollAnimation />} />
          <Route path="/controllserver" element={<DashBoard />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/location_policy" element={<LocationPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/ios_policy" element={<PolicyIOS />} />
          <Route path="/android_policy" element={<PolicyANDROID />} />
          <Route path="/dashboard" element={<DashBoard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
