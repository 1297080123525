import { FunctionComponent } from "react";
import BlogItem from "./BlogItem";
import { GridColumn2 } from "components/RootComponent.style";

export const BlogItemGrid: FunctionComponent = function () {
  return (
    <GridColumn2>
      <BlogItem
        buttonText={"press release"}
        title={"Myren : A new era of behavior-based insurance"}
        bagroundImgSrc={"img/DrivingImg.jpeg"}
      />
      <BlogItem
        buttonText={"press release"}
        title={"Myren : A new era of behavior-based insurance"}
        bagroundImgSrc={"img/DrivingImg.jpeg"}
      />
    </GridColumn2>
  );
};

export default BlogItemGrid;
