import { FunctionComponent, useEffect, useState } from "react";
import {
  InvestmentContents,
  InvestmentWrap,
} from "../ComponentStyle/HomeInvestment.style";

const HomeInvestment: FunctionComponent = function () {
  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  }, []);
  return (
    <InvestmentWrap
      className={scrollPosition > 100 ? "scroll-text" : "scrolled-text"}
    >
      <InvestmentContents style={{ backgroundColor: "#ff00ff" }}>
        first
      </InvestmentContents>
      <InvestmentContents style={{ backgroundColor: "#f00011" }}>
        second
      </InvestmentContents>
      <InvestmentContents style={{ backgroundColor: "#0f0fff" }}>
        third
      </InvestmentContents>
      <InvestmentContents style={{ backgroundColor: "#00ff0f" }}>
        fourth
      </InvestmentContents>
      <InvestmentContents style={{ backgroundColor: "#00ff0f" }}>
        fourth
      </InvestmentContents>
      <InvestmentContents style={{ backgroundColor: "#00ff0f" }}>
        fourth
      </InvestmentContents>
    </InvestmentWrap>
  );
};

export default HomeInvestment;
