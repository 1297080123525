import styled from "@emotion/styled";
import { defaultColorTheme, mainTheme } from "components/Layout/Theme";

export const AppPopupMiddle = styled.div`
  width: 20.5rem;
  height: 8.06rem;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 1rem;
  background-color: ${defaultColorTheme.White};
`;

export const AppPopupMiddleContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppPopupButtonDiv = styled.div`
  text-align: center;
  width: 7.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  font-size: 1rem;
  background-color: ${mainTheme.mainColor500};
  color: #fff;
`;

export const LottieDiv = styled.div`
  margin-left: auto;
  display: flex;
  width: 12.5rem;
  height: 12.5rem;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  align-items: center;
  @media screen and (max-width: 768px)  and (min-width: 320px){
    border-radius: 5px;
    width: 75px;
    height: 74px;
  }
`;
