import { CSSProperties, FunctionComponent } from "react";

export const BorderInput: FunctionComponent<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  type: string;
  placeholder: string;
  style?: CSSProperties | undefined;
}> = function (props) {
  return (
    <input
      type={props.type}
      onChange={props.onChange}
      value={props.value}
      style={props.style}
      placeholder={props.placeholder}
    />
  );
};
