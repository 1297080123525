import React, { FunctionComponent } from "react";
import { PopupRelative, PopupBackgroundDiv } from "../ComponentStyle/PopupItem.style";
import PopupItem from "./PopupItem";

export const PopupComponent: FunctionComponent = function () {
  return (
    <PopupRelative>
      <PopupBackgroundDiv />
      <PopupItem />
    </PopupRelative>
  );
};

export default PopupComponent;
