import {
  Spacer3em,
  SpacerBorder3em,
} from "components/StyleComponent/StyleComponents";
import { FunctionComponent, RefObject } from "react";

type TabComponentType = {
  RefElement: RefObject<HTMLDivElement>;
  ContentsArr: string[];
};

const TabContents: FunctionComponent<TabComponentType> = function ({
  RefElement,
  ContentsArr,
}) {
  return (
    <div ref={RefElement}>
      {ContentsArr.map((src, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={src} alt={index.toString()} style={{ width: "90%" }} />
          <SpacerBorder3em />
          <Spacer3em />
        </div>
      ))}
    </div>
  );
};

export default TabContents;
