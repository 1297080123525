import { styled } from "styled-components";

export const SecondContentsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    margin-top: 32px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-left: 7.03125vw;
  }
`;
