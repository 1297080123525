import styled from "@emotion/styled";
import { grayScaleTheme } from "components/Layout/Theme";

export const FlexBackground = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${grayScaleTheme.grayScaleColor300};
`;

export const FlexCenter = styled.div`
  display: flex;
  alignitems: center;
`;
export const GridColumn4to2 = styled.div`
  //   overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridItem = styled.div`
  //   min-width: 132px;
  min-height: 100px;
  padding: 12px;
  background-color: ${grayScaleTheme.grayScaleColor200};
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &:last-child {
    grid-column-end: span 2;
  }
`;

export const FlexSpaceBtwtoCenter = styled.div`
  display: flex;
  align-items: center;
  //   align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    justify-content: center;
  }
`;
