import { ColumnCenter, ContainerH4Div } from "components/RootComponent.style";
import { Spacer1em } from "components/StyleComponent/StyleComponents";
import React, { FunctionComponent } from "react";

const GridItemWithTextDetail: FunctionComponent = function () {
  return (
    <>
      <ColumnCenter style={{ textAlign: "center",alignItems: "center" }}>
        <img alt="" src="https://assets.website-files.com/608c6d5b86372756c37c44d3/60929417c6687e48d833b082_Benefits1.svg" />
        <Spacer1em />
        <ContainerH4Div>Well-being</ContainerH4Div>
        <Spacer1em />
        <p style={{ marginBottom: "0" }}>
          Your health and well-being always come first. You will have
          comprehensive health, dental, and vision plans and a generous family
          leave policy.
        </p>
      </ColumnCenter>
    </>
  );
};

export default GridItemWithTextDetail;
