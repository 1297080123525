import React, { FunctionComponent } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderContentItem from "./SliderContentItem";
import { SliderWrapper } from "../ComponentStyle/FourthContents.style";
import { Icon } from "@iconify/react";
import { grayScaleTheme } from "components/Layout/Theme";
import styled from "styled-components";

interface SampleArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const StyledSlider = styled(Slider)`
  height: 100%;
  width: 100%;
  position: relative;
  .slick-prev::before,
  .slick-next::before {
    opacity: 0;
    display: none;
  }
`;

const Pre = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 3%;
  top: 50%;
  z-index: 3;
`;

const NextTo = styled.div`
  width: 70px;
  height: 70px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 5%;
  top: 50%;
  z-index: 3;
`;

function SampleNextArrow(props: SampleArrowProps) {
  console.log(props.className);
  console.log(props.className?.includes("disable"));
  return (
    <NextTo onClick={props.onClick}>
      <Icon
        height={70}
        icon="material-symbols:arrow-forward-ios"
        color={grayScaleTheme.grayScaleColor300}
      ></Icon>
    </NextTo>
  );
}
function SamplePrevArrow(props: SampleArrowProps) {
  console.log(props.className);
  console.log(props.className?.includes("disable"));
  return (
    <Pre onClick={props.onClick}>
      <Icon
        height={70}
        icon="material-symbols:arrow-back-ios"
        color={grayScaleTheme.grayScaleColor300}
      ></Icon>
    </Pre>
  );
}

type ClickType = {
  currentTab: number;
  contents: string[];
};

const SliderComponent: FunctionComponent<ClickType> = function ({ contents }) {
  const settings = {
    dots: true,
    fade: true,
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SliderWrapper>
        <StyledSlider {...settings}>
          {contents.map((content) => (
            <SliderContentItem data={content} />
          ))}
        </StyledSlider>
      </SliderWrapper>
    </div>
  );
};

export default SliderComponent;
