import Footer from "components/Layout/Footer";
import {
  Container,
  GridColumn3,
  GridItemOdd,
  NoMarginCenterH1,
  GridRow1,
  GridRow21,
  GridRow11,
} from "components/RootComponent.style";
import Spacer8em, {
  Spacer4em,
  Spacer6em,
} from "components/StyleComponent/StyleComponents";
import TopBanner from "components/ThirdContents/TopBanner";
import { FunctionComponent } from "react";
import GridItemWithTextDetail from "./GridItemWithTextDetail";
import Menu from "components/Layout/Menu";
import { ContainerFlexCenter } from "components/ComponentStyle/TopBanner.style";
import { NoMarginH2 } from "components/ComponentStyle/TopBanner.style";
import HomeInvestment from "components/FirstContents/HomeInvestment";

const Company: FunctionComponent = function () {
  return (
    <>
      <Menu />
      <NoMarginH2 style={{ textAlign: "center" }}>Our Investors</NoMarginH2>
      <Spacer4em />
      <HomeInvestment />
      <Spacer8em />
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "39.58em" }}>
          <NoMarginCenterH1>Here’s why you should join MyRen</NoMarginCenterH1>
          <Spacer6em />
        </div>
      </Container>
      <Container>
        <GridItemOdd>
          <GridRow1>
            <div style={{ width: "10em", paddingRight: "2em" }}>
              <img
                alt=""
                src="https://assets.website-files.com/608c6d5b86372756c37c44d3/6092941b7c989e5a83e26733_WhyJoin3.svg"
              />
            </div>
            <div>
              <div>Innovative culture</div>
              <p>
                You are always encouraged to ask bold questions and challenge
                the status quo
              </p>
            </div>
          </GridRow1>

          <GridRow1>
            <div style={{ width: "10em", paddingRight: "2em" }}>
              <img
                alt=""
                src="https://assets.website-files.com/608c6d5b86372756c37c44d3/6092941b7c989e5a83e26733_WhyJoin3.svg"
              />
            </div>
            <div>
              <div>Innovative culture</div>
              <p>
                You are always encouraged to ask bold questions and challenge
                the status quo
              </p>
            </div>
          </GridRow1>

          <GridRow1>
            <div style={{ width: "10em", paddingRight: "2em" }}>
              <img
                alt=""
                src="https://assets.website-files.com/608c6d5b86372756c37c44d3/6092941b7c989e5a83e26733_WhyJoin3.svg"
              />
            </div>
            <div>
              <div>Innovative culture</div>
              <p>
                You are always encouraged to ask bold questions and challenge
                the status quo
              </p>
            </div>
          </GridRow1>

          <GridRow21>
            <div style={{ width: "10em", paddingRight: "2em" }}>
              <img
                alt=""
                src="https://assets.website-files.com/608c6d5b86372756c37c44d3/6092941b7c989e5a83e26733_WhyJoin3.svg"
              />
            </div>
            <div>
              <div>Innovative culture</div>
              <p>
                You are always encouraged to ask bold questions and challenge
                the status quo
              </p>
            </div>
          </GridRow21>

          <GridRow11>
            <div style={{ width: "10em", paddingRight: "2em" }}>
              <img
                alt=""
                src="https://assets.website-files.com/608c6d5b86372756c37c44d3/6092941b7c989e5a83e26733_WhyJoin3.svg"
              />
            </div>
            <div>
              <div>Innovative culture</div>
              <p>
                You are always encouraged to ask bold questions and challenge
                the status quo
              </p>
            </div>
          </GridRow11>
        </GridItemOdd>
      </Container>
      <TopBanner
        h2Contents={null}
        h2ContentsMarginBottom={null}
        plusContents={null}
        plusContents2={null}
        children={null}
      />
      <ContainerFlexCenter>
        <NoMarginH2 style={{ textAlign: "center" }}>Awards</NoMarginH2>
        <Spacer6em />
      </ContainerFlexCenter>
      <Container>사진들어가면 됨.</Container>
      <TopBanner
        h2Contents={"Team Testimonials"}
        plusContents={null}
        plusContents2={null}
        children={null}
        h2ContentsMarginBottom={false}
      />
      <Spacer6em />
      <TopBanner
        h2Contents={"Our benefits"}
        plusContents={
          "Here are a few of the many reasons why you should join Zendrive."
        }
        plusContents2={null}
        children={null}
        h2ContentsMarginBottom={true}
      />
      <Container>
        <GridColumn3>
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
          <GridItemWithTextDetail />
        </GridColumn3>
      </Container>
      <Spacer8em />
      {/* 채용 */}
      <Footer />
    </>
  );
};

export default Company;
