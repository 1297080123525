import { FunctionComponent } from "react";
import FixCopyContain from "./FixCopyContain";
import AnimationContain from "./AnimationContain";
import { SecondContentsContainer } from "../ComponentStyle/SecondContents.style";

const TopIntro: FunctionComponent = function () {
  return (
    <SecondContentsContainer>
      <FixCopyContain />
      <AnimationContain />
    </SecondContentsContainer>
  );
};

export default TopIntro;
