import { FunctionComponent } from "react";
import {
  MainCopy,
  SecondCopy,
  StrongCopy,
} from "./../ComponentStyle/FixCopyContain.style";
import { styled } from "styled-components";
import {} from "components/StyleComponent/FontStyle";
import { Spacer } from "components/StyleComponent/Spacer";

const FixCopyContain: FunctionComponent = function () {
  return (
    <FixCopyContainWrapper>
      <MainCopy>
        나만의 사이렌
        <StrongCopy>마이렌</StrongCopy>
      </MainCopy>
      <SecondCopy>
        마이렌은 "나(My)’와 사이렌(Siren)"이 합쳐진 안전 브랜드 입니다.
        <br />
        사고에 대한 돌발 상황 및 대처 방법을
        {window.innerWidth <= 320 ? <br /> : null}&nbsp;안내해드립니다.
      </SecondCopy>
      <div style={{ display: "flex", width: "50%" }}>
        <a href="https://play.google.com/store/apps/details?id=com.myren.myrens&pcampaignid=web_share">
          <img src="img/googlePlayStore.png" alt="" />
        </a>
        <Spacer space={"20px"} />
        <a href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%A0%8C-%EC%9E%90%EB%8F%99%EC%B0%A8-%EC%82%AC%EA%B3%A0-%EC%8B%A0%EA%B3%A0/id6453695163">
          <img src="img/appleAppStore.png" alt="" />
        </a>
      </div>
    </FixCopyContainWrapper>
  );
};
const FixCopyContainWrapper = styled.div`
  width: 39.58em;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: 100%;
  }
`;
export default FixCopyContain;
