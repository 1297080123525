import styled from "@emotion/styled";
import { FunctionComponent } from "react";

type Contents = {
  contents: string | null;
};
export const Spacer20em = function () {
  return <div style={{ height: "20em" }} />;
};
export const Spacer10em = function () {
  return <div style={{ height: "10em" }} />;
};
export const Spacer8em = function () {
  return <div style={{ height: "8em" }} />;
};
export const Spacer15rem = function () {
  return <div style={{ height: "15rem" }} />;
};

export const Spacer6em = function () {
  return <div style={{ height: "6em" }} />;
};

export const Spacer5em = function () {
  return <div style={{ height: "5em" }} />;
};

export const Spacer4em = function () {
  return <div style={{ height: "4em" }} />;
};

export const Spacer3em = function () {
  return <div style={{ height: "3em" }} />;
};
export const SpacerBorder3em = function () {
  return (
    <div
      style={{
        width: "90%",
        height: "3em",
        borderBottom: "1px solid #DADADA",
      }}
    />
  );
};
export const Spacer3_25em = function () {
  return <div style={{ height: "3.25em" }} />;
};
export const Spacer2em = function () {
  return <div style={{ height: "2em" }} />;
};
export const Spacer1em = function () {
  return <div style={{ height: "1em" }} />;
};

export const SpacerWidth2em = function () {
  return <div style={{ width: "2em" }} />;
};

export const ContainerH4: FunctionComponent<Contents> = function (
  props: Contents
) {
  return (
    <div
      style={{
        fontFamily: "Maven Pro,sans-serif",
        fontSize: "1.333em",
        fontWeight: "600",
        lineHeight: "1.3",
        marginBottom: "0",
      }}
    >
      {props.contents}
    </div>
  );
};

export const ContainerH1: FunctionComponent<Contents> = function (
  props: Contents
) {
  return (
    <div
      style={{
        fontFamily: "Maven Pro,sans-serif",
        fontSize: "3.157em",
        fontWeight: "600",
        lineHeight: "1.1",
        marginBottom: "0",
      }}
    >
      {props.contents}
    </div>
  );
};

export const SidePadding20px = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const FlexContaier = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexContaierColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Spacer8em;
