import styled from "@emotion/styled";
import { defaultColorTheme, mainTheme } from "./Theme";
import { grayScaleTheme } from "./Theme";

export const FooterWrapper = styled.div`
  background-color: ${grayScaleTheme.grayScaleColor800};
  padding-top: 44px;
  padding-bottom: 44px;
  padding-right: 72px;
  padding-left: 72px;
  display: flex;
  // flex-direction:column;
  height: 290px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    align-items: flex-start;
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 48px;
    padding-left: 72px;
  }
`;

export const FooterImgWrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 720px) {
    padding-bottom: 10px;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const FooterContentsContainer = styled.div`
  justify-content: space-between;
  display: flex;

  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5em;
  padding-right: 5em;
`;

export const FooterContentsListContainer = styled.div`
  justify-content: center;
  display: flex;
`;

export const FooterContentsListBigCol = styled.div`
  display: flex;
`;

export const FooterContentsListSmallCol = styled.div`
  padding-right: 5em;
`;

export const FooterContentsListElementWrap = styled.div`
  padding-bottom: 1em;
  font-size: 0.9em;
  display: flex;
`;

export const FooterButtonContainer = styled.div`
  width: 25.69em;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
`;

export const FooterButton = styled.a`
  font-size: 0.9em;

  height: 3.06em;
  color: ${defaultColorTheme.White};
  text-align: center;
  background-color: ${mainTheme.mainColor500};
  border-radius: 40px;
  padding: 0 1.5em;
  line-height: 3.06em;
  text-decoration: none;
`;

export const FooterSocialWrap = styled.div`
  display: flex;
`;

export const FooterSocialButton = styled.a`
  margin-right: 1em;

  width: 2.2em;
  height: 2.2em;
  background-color: #151d45;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  max-width: 100%;

  color: #151d45;
  text-decoration: underline;
`;

export const FooterSocialImg = styled.img`
  z-index: 1;
  max-width: 50%;
  position: absolute;

  vertical-align: middle;
  display: inline-block;

  border: 0;
`;

export const FooterSocialBg = styled.div`
  z-index: 0;
  width: 100%;
  opacity: 1;
  background-color: #151d45;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
`;

export const FooterLogo = styled.img`
  @media screen and (max-width: 720px) {
    width: 75px;
    height: 16px;
    margin-right: 28px;
  }
  @media screen and (min-width: 721px) and (max-width: 1024px) {
    width: 112px;
    height: 24px;
    margin-right: 28px;
  }
`;

export const FooterMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 908px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 235px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    width: 438px;
  }
`;
