import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

const ScrollAnimation: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  // 스크롤 이벤트 핸들러
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // react-spring의 useSpring 훅을 사용하여 애니메이션 값 설정
  const animation = useSpring({
    number: scrollPosition > 10 ? 3000 : 0, // 스크롤 위치에 따라 0 또는 3000으로 설정
    from: { number: 0 }, // 애니메이션 시작값
    config: { duration: 1000 }, // 애니메이션 지속시간 설정
  });

  return (
    <div>
      <h1>Scroll Animation</h1>
      <div style={{ height: "30vh" }} />
      <div style={{ height: "30vh" }} />
      <div style={{ height: "30vh" }} />
      <animated.span>
        {animation.number.interpolate((val: number) => Math.floor(val))}
      </animated.span>
    </div>
  );
};

export default ScrollAnimation;
