import { FunctionComponent } from "react";
import { styled } from "styled-components";

const AnimationContain: FunctionComponent = function () {
  return (
    <ImgWrapper>
      <img src="/img/mockUP1_car.png" alt="" />
    </ImgWrapper>
  );
};

const ImgWrapper = styled.div`
  width: 39.0625vw;
  @media screen and (max-width: 1024px) {
    width: 70.3125vw;
    margin-left: 7.8125vw;
  }
`;

export default AnimationContain;
