import { FunctionComponent } from "react";
import {
  BlogItemImg,
  BlogItemImgIndex,
  BlogItemLinkA,
  BlogItemLinkSmallText,
  BlogItemLinkTag,
  BlogItemLinkWrapper,
  BlogItemTextA,
  BlogItemTextWrapper,
} from "../ComponentStyle/Blog.style";
import {
  Spacer2em,
  Spacer4em,
} from "components/StyleComponent/StyleComponents";

export const BlogItem: FunctionComponent<{
  bagroundImgSrc: string;
  buttonText?: string | null;
  title: string;
}> = function (props) {
  return (
    <div style={{ position: "relative" }}>
      {props.buttonText ? (
        <BlogItemLinkWrapper>
          <BlogItemLinkTag>
            <BlogItemLinkSmallText>{props.buttonText}</BlogItemLinkSmallText>
          </BlogItemLinkTag>
        </BlogItemLinkWrapper>
      ) : null}

      <BlogItemLinkA>
        <BlogItemImg src={props.bagroundImgSrc} />
      </BlogItemLinkA>
      <BlogItemImgIndex></BlogItemImgIndex>
      <BlogItemTextWrapper>
        <Spacer4em />
        <BlogItemTextA>{props.title}</BlogItemTextA>
        <Spacer2em />
      </BlogItemTextWrapper>
    </div>
  );
};

export default BlogItem;
