import { FunctionComponent, useCallback, useState } from "react";
import {
  FooterWrapper,
  FooterLogo,
  FooterMenu,
  FooterImgWrapper,
} from "./Footer.style";
import {
  MediumBody01,
  RegularBody02,
  SemiBoldHeadLine02,
  TypographyStyle,
  TypographyComponent,
} from "components/StyleComponent/FontStyle";
import { defaultColorTheme, grayScaleTheme, primaryColorTheme } from "./Theme";
import { ContactBtn } from "./Menu.style";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ContentsColor } from "./Theme";
import { Spacer } from "components/StyleComponent/Spacer";
import Modal from "./Modal";
const Footer: FunctionComponent = function () {
  const [isOpenModal, setOpenModal] = useState(false);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal} Footer={true}>
          <div
            style={{
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              boxShadow:
                "0px 4px 12px 0px rgba(0, 0, 0, 0.22),0px 4px 24px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "16px",
                backgroundColor: primaryColorTheme.primaryColor500,
                color: defaultColorTheme.White,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {window.innerWidth > 320 ? (
                <>
                  <TypographyComponent.heading.bold20>
                    제휴 문의
                  </TypographyComponent.heading.bold20>
                </>
              ) : (
                <>
                  <TypographyComponent.label.semiBold16>
                    제휴 문의
                  </TypographyComponent.label.semiBold16>
                </>
              )}
            </div>
            <div
              style={
                window.innerWidth > 320
                  ? {
                      padding: "32px 48px",
                      alignItems: "center",
                      borderBottom: "1px solid #e1e1e1",
                    }
                  : {
                      padding: "32px 32px",
                      alignItems: "center",
                      borderBottom: "1px solid #e1e1e1",
                    }
              }
            >
              {window.innerWidth > 320 ? (
                <>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular16,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TypographyComponent.paragraphRegular.paragraphRegular16
                      style={{ color: primaryColorTheme.primaryColor500 }}
                    >
                      info@myren.kr
                    </TypographyComponent.paragraphRegular.paragraphRegular16>
                    로 제휴문의를 보내주세요.
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TypographyComponent.paragraphRegular.paragraphRegular16
                      style={{ textAlign: "center" }}
                    >
                      보다 더 정확하고 상세한 답변을 원하신다면,
                      <br />
                      아래의 내용을 포함한 문의를 부탁드립니다.
                    </TypographyComponent.paragraphRegular.paragraphRegular16>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TypographyComponent.paragraphRegular.paragraphRegular12
                      style={{ color: primaryColorTheme.primaryColor500 }}
                    >
                      info@myren.kr
                    </TypographyComponent.paragraphRegular.paragraphRegular12>
                    <TypographyComponent.paragraphRegular.paragraphRegular12>
                      로 제휴문의를 보내주세요.
                    </TypographyComponent.paragraphRegular.paragraphRegular12>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TypographyComponent.paragraphRegular.paragraphRegular12
                      style={{ textAlign: "center" }}
                    >
                      보다 더 정확하고 상세한 답변을 원하신다면,
                      <br />
                      아래의 내용을 포함한 문의를 부탁드립니다.
                    </TypographyComponent.paragraphRegular.paragraphRegular12>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                padding: "20px 16px",
                backgroundColor: grayScaleTheme.grayScaleColor50,
              }}
            >
              <ul
                style={
                  window.innerWidth > 320
                    ? {
                        ...TypographyStyle.paragraphRegular.paragraphRegular12,
                        color: grayScaleTheme.grayScaleColor700,
                        margin: 0,
                        padding: 0,
                        listStylePosition: "inside",
                      }
                    : {
                        ...TypographyStyle.paragraphRegular.paragraphRegular10,
                        color: grayScaleTheme.grayScaleColor700,
                        margin: 0,
                        padding: 0,
                        listStylePosition: "inside",
                      }
                }
              >
                <li>
                  제휴 구분 &#40;법인 제휴, 사업 제휴, 마케팅 제휴, 광고 제휴
                  등&#41;
                </li>
                <li>제안 배경 / 세부 제안 내용 / 제휴 기대 효과 등</li>
                <li>&#40;필요 시&#41;파일 첨부</li>
              </ul>
            </div>
          </div>
        </Modal>
      )}
      <FooterWrapper>
        <div style={{ width: "100%" }}>
          <FooterImgWrapper>
            <FooterLogo src="./img/LogoWhite.png" alt="" />
            {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
              <ContactBtn
                onClick={() => {
                  setOpenModal(!isOpenModal);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Icon icon={"mdi:email-outline"} width={"16px"}></Icon>
                </div>
              </ContactBtn>
            ) : window.innerWidth <= 1024 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "225px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/myren.inc.official/"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"mdi:instagram"}
                        fontSize={24}
                        color={ContentsColor.contentSecondary}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      icon={"ic:baseline-facebook"}
                      fontSize={24}
                      color={ContentsColor.contentSecondary}
                    />
                  </div> */}
                  <a
                    href="https://blog.naver.com/myrenofficial"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "36px",
                        border: "2px solid",
                        borderColor: ContentsColor.contentSecondary,
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="img/NaverLogo.png"
                        alt=""
                        style={{ width: "18px" }}
                      />
                    </div>
                  </a>
                  {/* <a
                    href="https://www.youtube.com/@Myren_official"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "36px",
                        border: "2px solid",
                        borderColor: ContentsColor.contentSecondary,
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        icon={"mdi:youtube"}
                        fontSize={24}
                        color={ContentsColor.contentSecondary}
                      />
                    </div>
                  </a> */}
                  {/* <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%A0%8C-%EC%9E%90%EB%8F%99%EC%B0%A8-%EC%82%AC%EA%B3%A0-%EC%8B%A0%EA%B3%A0/id6453695163"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"bxl:apple"}
                        fontSize={25}
                        color={ContentsColor.contentSecondary}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "1px",
                          paddingBottom: "2px",
                        }}
                      />
                    </a>
                  </div> */}
                  {/* <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.myren.myrens&pcampaignid=web_share"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"bxl:play-store"}
                        fontSize={25}
                        color={ContentsColor.contentSecondary}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: "2px",
                          paddingBottom: "1px",
                        }}
                      />
                    </a>
                  </div> */}
                </div>
                <ContactBtn
                  onClick={() => {
                    setOpenModal(!isOpenModal);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Icon icon={"mdi:email-outline"} width={"24px"}></Icon>
                    <TypographyComponent.paragraphMedium.paragraphMedium16>
                      제휴 문의
                    </TypographyComponent.paragraphMedium.paragraphMedium16>
                  </div>
                </ContactBtn>
              </div>
            ) : (
              // 제일 큰 사이즈
              <>
                <Spacer space={"40px"} />
                <div
                  style={{
                    display: "flex",
                    width: "275.6px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/myren.inc.official/"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"mdi:instagram"}
                        fontSize={21}
                        color={ContentsColor.contentSecondary}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      icon={"ic:baseline-facebook"}
                      fontSize={21}
                      color={ContentsColor.contentSecondary}
                    />
                  </div> */}
                  <a
                    href="https://blog.naver.com/myrenofficial"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "36px",
                        border: "2px solid",
                        borderColor: ContentsColor.contentSecondary,
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="img/NaverLogo.png"
                        alt=""
                        style={{ width: "18px" }}
                      />
                    </div>
                  </a>
                  {/* <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.youtube.com/@Myren_official"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"mdi:youtube"}
                        fontSize={21}
                        color={ContentsColor.contentSecondary}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%A0%8C-%EC%9E%90%EB%8F%99%EC%B0%A8-%EC%82%AC%EA%B3%A0-%EC%8B%A0%EA%B3%A0/id6453695163"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"bxl:apple"}
                        fontSize={25}
                        color={ContentsColor.contentSecondary}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "1px",
                          paddingBottom: "2px",
                        }}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid",
                      borderColor: ContentsColor.contentSecondary,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.myren.myrens&pcampaignid=web_share"
                      style={{ display: "flex" }}
                    >
                      <Icon
                        icon={"bxl:play-store"}
                        fontSize={25}
                        color={ContentsColor.contentSecondary}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: "2px",
                          paddingBottom: "1px",
                        }}
                      />
                    </a>
                  </div> */}
                </div>
              </>
            )}
          </FooterImgWrapper>

          {/* 모바일 환경 */}
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "204px",
                  justifyContent: "space-between",
                }}
              >
                <a
                    href="https://blog.naver.com/myrenofficial"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        border: "2px solid",
                        borderColor: ContentsColor.contentSecondary,
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="img/NaverLogo.png"
                        alt=""
                        style={{ width: "12px" }}
                      />
                    </div>
                  </a>
                {/* <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.instagram.com/myren.inc.official/"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"mdi:instagram"}
                      fontSize={14}
                      color={ContentsColor.contentSecondary}
                    />
                  </a>
                </div> */}
                {/* <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    icon={"ic:baseline-facebook"}
                    fontSize={16}
                    color={ContentsColor.contentSecondary}
                  />
                </div> */}
                {/* <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.instagram.com/myren.inc.official/"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"mdi:instagram"}
                      fontSize={14}
                      color={ContentsColor.contentSecondary}
                    />
                  </a>
                </div>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.youtube.com/@Myren_official"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"mdi:youtube"}
                      fontSize={16}
                      color={ContentsColor.contentSecondary}
                    />
                  </a>
                </div>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%A0%8C-%EC%9E%90%EB%8F%99%EC%B0%A8-%EC%82%AC%EA%B3%A0-%EC%8B%A0%EA%B3%A0/id6453695163"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"bxl:apple"}
                      fontSize={14}
                      color={ContentsColor.contentSecondary}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // paddingRight: "1px",
                        // paddingBottom: "2px",
                      }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "2px solid",
                    borderColor: ContentsColor.contentSecondary,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.myren.myrens&pcampaignid=web_share"
                    style={{ display: "flex" }}
                  >
                    <Icon
                      icon={"bxl:play-store"}
                      fontSize={14}
                      color={ContentsColor.contentSecondary}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // paddingLeft: "2px",
                        // paddingBottom: "1px",
                      }}
                    />
                  </a>
                </div> */}
              </div>
              <Spacer horizontal={true} space={"20px"} />
              <div
                style={
                  window.innerWidth >= 320 && window.innerWidth <= 768
                    ? { display: "flex", flexDirection: "column" }
                    : { display: "flex" }
                }
              >
                <FooterMenu>
                  <Link to={"/main"}>
                    <MediumBody01 style={{ color: defaultColorTheme.White }}>
                      회사소개
                    </MediumBody01>
                  </Link>
                  <Link to={"/terms"}>
                    <MediumBody01 style={{ color: defaultColorTheme.White }}>
                      이용약관
                    </MediumBody01>
                  </Link>
                  <Link to={"/policy"}>
                    <MediumBody01 style={{ color: defaultColorTheme.White }}>
                      개인정보처리방침
                    </MediumBody01>
                  </Link>
                </FooterMenu>
                <FooterMenu style={{ width: "155px" }}>
                  <Link to={"/location_policy"}>
                    <MediumBody01 style={{ color: defaultColorTheme.White }}>
                      위치정보 이용약관
                    </MediumBody01>
                  </Link>
                  <Link to={"/main"}>
                    <MediumBody01 style={{ color: defaultColorTheme.White }}>
                      고객센터
                    </MediumBody01>
                  </Link>
                </FooterMenu>
                <div style={{}}>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    주소. 충청남도 천안시 천안천4길 32, 그린스타트업타운
                    412호&#40;와촌동&#41;
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    주식회사 마이렌
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    사업자등록번호 : 261-86-01229, 대표자 : 최은홍
                  </RegularBody02>
                  <RegularBody02
                    style={{ color: grayScaleTheme.grayScaleColor500 }}
                  >
                    © 2023 MYREN All rights reserved.
                  </RegularBody02>
                </div>
              </div>
            </>
          ) : null}

          {/* 일반 디스플레이 */}
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? null : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{}}>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  주소. 충청남도 천안시 천안천4길 32, 그린스타트업타운
                  412호&#40;와촌동&#41;
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  주식회사 마이렌
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  사업자등록번호 : 261-86-01229, 대표자 : 최은홍
                </RegularBody02>
                <RegularBody02
                  style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  © 2023 MYREN All rights reserved.
                </RegularBody02>
              </div>
              <FooterMenu>
                <Link to={"/main"}>
                  <SemiBoldHeadLine02
                    style={{ color: defaultColorTheme.White }}
                  >
                    회사소개
                  </SemiBoldHeadLine02>
                </Link>
                <Link to={"/terms"}>
                  <SemiBoldHeadLine02
                    style={{ color: defaultColorTheme.White }}
                  >
                    이용약관
                  </SemiBoldHeadLine02>
                </Link>
                <Link to={"/policy"}>
                  <SemiBoldHeadLine02
                    style={{ color: defaultColorTheme.White }}
                  >
                    개인정보처리방침
                  </SemiBoldHeadLine02>
                </Link>
                <Link to={"/location_policy"}>
                  <SemiBoldHeadLine02
                    style={{ color: defaultColorTheme.White }}
                  >
                    위치정보 이용약관
                  </SemiBoldHeadLine02>
                </Link>

                <Link to={"/main"}>
                  <SemiBoldHeadLine02
                    style={{ color: defaultColorTheme.White }}
                  >
                    고객센터
                  </SemiBoldHeadLine02>
                </Link>
                {window.innerWidth > 1024 ? (
                  <ContactBtn
                    onClick={() => {
                      setOpenModal(!isOpenModal);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Icon icon={"mdi:email-outline"} width={"24px"}></Icon>
                      <TypographyComponent.paragraphMedium.paragraphMedium16>
                        제휴 문의
                      </TypographyComponent.paragraphMedium.paragraphMedium16>
                    </div>
                  </ContactBtn>
                ) : null}
              </FooterMenu>
            </div>
          )}
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
