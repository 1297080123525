import { FunctionComponent } from "react";
import {
  SliderLogoWarp,
  SliderWarp,
} from "../ComponentStyle/SliderContents.style";

type Data = {
  data: string;
};

const SliderContentItem: FunctionComponent<Data> = function ({ data }) {
  return (
    <>
      <SliderWarp>
        <SliderLogoWarp>
          <img style={{ width: "90%" }} src={data} alt="" />
        </SliderLogoWarp>
      </SliderWarp>
    </>
  );
};

export default SliderContentItem;
