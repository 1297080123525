import { FunctionComponent, useEffect } from "react";
import {
  CardSectionFlex,
  FlexChildStretchContainer,
  SolutionContainer,
  SolutionDetailP,
  SolutionMainH3,
  SolutionTextContainer,
  Spacer2em,
} from "../ComponentStyle/ContentsCard.style";
import { Player } from "@lottiefiles/react-lottie-player";
import IntroAniComponent from "components/StyleComponent/IntroAniComponent/IntroAniComponent";
import { styled } from "styled-components";
import { Spacer } from "components/StyleComponent/Spacer";

//side => right : true
//        left : false
type CardProps = {
  side: boolean | any;
  title: string | null;
  lottieURL: string;
  imgURL: string;
  children: React.ReactNode;
};

const ContentsCard: FunctionComponent<CardProps> = function (props: CardProps) {
  useEffect(() => {
    const resizeListener = () => {};
    window.addEventListener("resize", resizeListener);
  });

  return (
    <CardSectionFlex style={props.side ? { backgroundColor: "#FAFAFA" } : {}}>
      <FlexChildStretchContainer>
        <SolutionContainer className={props.side ? "reverse" : ""}>
          {/* left */}
          {props.side ? (
            props.lottieURL && props.imgURL ? (
              <Player
                autoplay
                loop
                src={props.lottieURL}
                style={{ height: "300px", width: "300px" }}
              />
            ) : (
              // <ImgWrapper>style={{ objectFit: "cover" }}
              <ImgTag src={props.imgURL} alt="" />
              // </ImgWrapper>
            )
          ) : null}
          {props.side ? <Spacer space={"50px"} /> : null}
          <SolutionTextContainer>
            <SolutionMainH3>{props.title}</SolutionMainH3>
            <Spacer2em />
            <SolutionDetailP>{props.children}</SolutionDetailP>
          </SolutionTextContainer>
          {!props.side ? <Spacer space={"50px"} /> : null}
          {/* right */}
          <div>
            {props.side ? null : props.imgURL ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {/* style={{ objectFit: "cover" }} */}
                <ImgTag  src={props.imgURL} alt="" />
              </div>
            ) : (
              <IntroAniComponent />
            )}
          </div>
        </SolutionContainer>
      </FlexChildStretchContainer>
    </CardSectionFlex>
  );
};

const ImgWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    margin-left: 48px;
    max-height: 400px;
  }
`;

const ImgTag = styled.img`
  @media screen and (max-width: 475px) {
    max-height: 400px;
  }
`;

export default ContentsCard;
