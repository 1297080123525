import styled from "@emotion/styled";

export const IconGrid = styled.div`
  display: grid;
  grid-column-gap: 2.5em;
  grid-row-gap: 2.5em;
  grid-template-rows: auto;
  grid-template-columns: minmax(130px, 360px);
  grid-auto-columns: minmax(130px, 360px);
  grid-auto-flow: column;
  align-items: start;
`;

export const IconItemContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  text-align: center;
`;
