import Footer from "components/Layout/Footer";
import Spacer8em, {
  Spacer3_25em,
  Spacer6em,
} from "components/StyleComponent/StyleComponents";
import TopBanner from "components/ThirdContents/TopBanner";
import { FunctionComponent } from "react";
import Menu from "components/Layout/Menu";
import Tab from "components/ThirdContents/Tab";

const Platform: FunctionComponent = function () {
  return (
    <div id="app">
      <Menu />
      <TopBanner
        h2Contents={"마이렌 사용안내"}
        plusContents={""}
        plusContents2={""}
        children={null}
        h2ContentsMarginBottom={false}
      />
      {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
        <Spacer3_25em />
      ) : (
        <Spacer6em />
      )}
      <Tab />
      <Spacer8em />
      <Footer />
    </div>
  );
};

export default Platform;
