import React, { FunctionComponent } from "react";
import { MenuItemsWrapper, MenuItemWrapper } from "./Menu.style";
import { Link } from "react-router-dom";
import { SidePadding20px } from "components/StyleComponent/StyleComponents";
import {
  BoldHeadLine02,
  SemiBoldSubHead01,
} from "components/StyleComponent/FontStyle";

type MenuProps = {
  className: string | undefined;
  children?: React.ReactNode;
};

const MenuContents: FunctionComponent<MenuProps> = function ({ className }) {
  return (
    <MenuItemsWrapper className={className}>
      <MenuItemWrapper>
        <Link to={"/main"} style={{ display: "flex", textAlign: "center" }}>
          <SidePadding20px>
            {window.innerWidth <= 1024 ? (
              <SemiBoldSubHead01>마이렌 소개</SemiBoldSubHead01>
            ) : (
              <BoldHeadLine02>마이렌 소개</BoldHeadLine02>
            )}
          </SidePadding20px>
        </Link>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <Link to={"/platform"} style={{ display: "flex", textAlign: "center" }}>
          <SidePadding20px>
            {window.innerWidth <= 1024 ? (
              <SemiBoldSubHead01>사용 안내</SemiBoldSubHead01>
            ) : (
              <BoldHeadLine02>사용 안내</BoldHeadLine02>
            )}
          </SidePadding20px>
        </Link>
      </MenuItemWrapper>
    </MenuItemsWrapper>
  );
};

export default MenuContents;
