import { FunctionComponent } from "react";
import BlogItemGrid from "./BlogItemGrid";
import Spacer8em from "components/StyleComponent/StyleComponents";
import { Container } from "components/RootComponent.style";


export const BlogContents: FunctionComponent = function () {
  return (
    <div>
      <Spacer8em />
      <Container>
        <div>
          <BlogItemGrid />
        </div>
      </Container>
      <Spacer8em />
    </div>
  );
};

export default BlogContents;
