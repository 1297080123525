import React, { FunctionComponent } from "react";
import {
  AppPopupButtonDiv,
  AppPopupMiddle,
  AppPopupMiddleContentsContainer,
  LottieDiv,
} from "./AppPopup";
import { FlexContaier, FlexContaierColumn } from "../StyleComponents";
import { MediumBody01, SemiBoldDisplay01 } from "../FontStyle";
import { grayScaleTheme } from "components/Layout/Theme";
import { Player } from "@lottiefiles/react-lottie-player";

type AppPopupType = {
  middTitle: string | null;
  mainTitle: string | null;
  buttonText: string | null;
  explainTitle: string | null;
};

type LottieType = {
  aniSrc: string;
};

export const LottieAniComponent: FunctionComponent<LottieType> = function (
  props: LottieType
) {
  return (
    <LottieDiv>
      <Player autoplay loop src={props.aniSrc} style={{ objectFit: "cover" }} />
    </LottieDiv>
  );
};

const AppPopup: FunctionComponent<AppPopupType> = function (
  props: AppPopupType
) {
  return (
    <>
      <AppPopupMiddle>
        <AppPopupMiddleContentsContainer>
          <FlexContaierColumn>
            <MediumBody01 style={{ color: grayScaleTheme.grayScaleColor600 }}>
              {props.middTitle}
            </MediumBody01>
            <FlexContaier>
              <SemiBoldDisplay01
                style={{
                  color: grayScaleTheme.grayScaleColor800,
                  letterSpacing: "-0.6px",
                }}
              >
                {props.mainTitle}
              </SemiBoldDisplay01>
              <AppPopupButtonDiv>{props.buttonText}</AppPopupButtonDiv>
            </FlexContaier>
            <div
              style={{
                height: "0.75em",
              }}
            />
            <div
              style={{
                height: "0.03em",
                backgroundColor: grayScaleTheme.grayScaleColor400,
              }}
            />
            <div
              style={{
                height: "0.5em",
              }}
            />
          </FlexContaierColumn>

          <FlexContaier style={{ alignItems: "center" }}>
            <MediumBody01 style={{ color: grayScaleTheme.grayScaleColor600 }}>
              {props.explainTitle}
            </MediumBody01>
            <div
              style={{
                position: "relative",
                backgroundColor: grayScaleTheme.grayScaleColor500,
                width: "51px",
                height: "21px",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  backgroundColor: grayScaleTheme.grayScaleColor50,
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.20)",
                  width: "30px",
                  height: "30px",
                  border: "2px solid #f6f6f6",
                  borderRadius: "50%",
                  top: "50%",
                  marginTop: "-15px",
                  marginLeft: "-5px",
                }}
              />
            </div>
          </FlexContaier>
        </AppPopupMiddleContentsContainer>
      </AppPopupMiddle>
    </>
  );
};

export default AppPopup;
