import { ContainerH4Div } from "components/RootComponent.style";
import { Spacer2em } from "components/StyleComponent/StyleComponents";
import React, { FunctionComponent } from "react";
import { IconItemContainer } from "../ComponentStyle/Icon.style";

const IconItem: FunctionComponent<{
  imgSrc: string;
  explainText: string;
}> = function (props) {
  return (
    <>
      <IconItemContainer>
        <img style={{ width: "5em" }} src={props.imgSrc} alt=""/>
        <Spacer2em />
        <ContainerH4Div className="_10ch">{props.explainText}</ContainerH4Div>
      </IconItemContainer>
    </>
  );
};

export default IconItem;
