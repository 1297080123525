import { FunctionComponent } from "react";
import ContentsCard from "./ContentsCard";
import { grayScaleTheme } from "components/Layout/Theme";
import {
  BoldDisplay05,
  BoldHeadLine02,
} from "components/StyleComponent/FontStyle";

const ThirdContents: FunctionComponent = function () {
  return (
    <div>
      <div
        style={
          window.innerWidth >= 320 && window.innerWidth <= 768
            ? {
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
                marginBottom: "52px",
                borderTop: "1px solid",
                borderColor: grayScaleTheme.grayScaleColor400,
              }
            : {
                height: "160px",
                display: "flex",
                justifyContent: "center",
                paddingTop: "100px",
                marginBottom: "120px",
                borderTop: "1px solid",
                borderColor: grayScaleTheme.grayScaleColor400,
              }
        }
      >
        {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
          <BoldHeadLine02>마이렌 주요 기능</BoldHeadLine02>
        ) : (
          <BoldDisplay05>마이렌 주요 기능 </BoldDisplay05>
        )}
      </div>
      {/* right card */}
      <ContentsCard
        side={false}
        title={"실시간 사고감지"}
        lottieURL={""}
        imgURL={"./img/DirectSiren.png"}
      >
        마이렌 앱에서 실시간 사고 감지 모드를 켜고 끌 수 있습니다.
        <br />
        {/* 자동차, 자전거, 휠체어, 보행자 4개 모드 중 하나를 선택할 수 있습니다. */}
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={true}
        title={"자동 사고 신고"}
        lottieURL={""}
        imgURL={"./img/AutoSirenImg.png"}
      >
        사고가 감지되면 30초 카운트다운이 시작됩니다.
        <br />
        30초 내에 응답하지 않으면 자동으로 신고가 진행됩니다.
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={false}
        title={"사고 신고 완료"}
        lottieURL={""}
        imgURL={"./img/AccidentDone.png"}
      >
        사고에 대한 정보를 유관기관 및 관계자에게 전송합니다.
        <br />
        사고 정보는 신고자, 사고유형, 사고일시가 포함됩니다.
      </ContentsCard>
      {/* right card */}
      <ContentsCard
        side={true}
        title={"사고 대응요령"}
        lottieURL={""}
        imgURL={"./img/AccidentReact.png"}
      >
        교통사고 발생 시 대응요령 5가지에 대해 안내해 드립니다.
        <br />
        2차 교통사고 예방 안내, 부상자 구호, 사고현장 촬영 등의 가이드라인을
        제공합니다.
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={false}
        title={"신고 내역"}
        lottieURL={""}
        imgURL={"./img/SirenRecord.png"}
      >
        사용자의 사고신고 내역을 표시합니다.
        <br />
        눌러서 상세 사고 정보를 확인할 수 있습니다.
      </ContentsCard>
    </div>
  );
};

export default ThirdContents;
