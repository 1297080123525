import styled from "@emotion/styled";

export const PopupRelative = styled.div`
  background-color: #f4f4f4;
  position: relative;
`;

export const PopupBackgroundDiv = styled.div`
  height: 5em;
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
`;

export const PopupContainer = styled.div`
  max-width: 1919px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5em;
  padding-right: 5em;
  display: block;
`;

export const PopupContainerCata = styled.div`
  z-index: 10;
  height: 11.81em;
  max-width: 1919px;
  background-color: #151d45;
  border-radius: 20px;
  justify-content: space-between;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding-right: 3em;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const PopupTextAreaSpace = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 60.42em;
`;

export const PopupTextWrapper = styled.div`
  z-index: 10;
  padding-left: 5em;
  position: relative;
`;

export const PopupTextDiv = styled.div`
  color: #fff;
  font-family: Maven Pro, sans-serif;
  font-size: 1.777em;
  font-weight: 600;
  line-height: 1.3;
`;

export const PopupButtonAreaSpace = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;

  width: 11.81em;
`;

export const PopupButtonA = styled.a`
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  transition: 0.5s;
  &.back-black {
    &:hover {
      color: #f0764c;
      background-color: #fff;
    }
  }
  &:hover {
    color: #f0764c;
    border: 1px solid #f0764c;
    .back-black {
      backgound-color: #fff;
    }
  }
`;
