import styled from "@emotion/styled";
import { grayScaleTheme } from "components/Layout/Theme";

export const MainCopy = styled.h2`
  margin-top: 0;
  margin-bottom: 1.2rem;
  font-family: Maven Pro, sans-serif;
  font-size: 2.369rem;
  font-weight: 600;
  line-height: 1.2;
  @media screen and (max-width: 768px) and (min-width: 320px) {
  margin-bottom: 1rem;
    font-weight: 700;
    letter-spacing: -0.7px;
    font-size: 1.38em; //40px
    line-height: 1.88rem;
  }
`;

export const StrongCopy = styled.h1`
  margin-top: 0;
  color: #e55622;
  font-family: Maven Pro, sans-serif;
  font-size: 5rem;
  font-weight: 800;
  line-height: 1.2;
  @media screen and (min-width:320px)and (max-width:720px){
  font-weight: 800;
  font-size: 3rem;
  }
`;

export const SecondCopy = styled.p`
  margin-bottom: 1.6rem;
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 300;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    color:${grayScaleTheme.grayScaleColor600}
    font-weight: 500;
    letter-spacing: -0.6px;
    font-size: 1rem; //40px
    line-height: 1.5rem;
  }
`;

export const LearnMoreBtn = styled.a`
  height: 3.06rem;
  color: #151d45;
  text-align: center;
  background-color: #00cc9b;
  border-radius: 40px;
  padding: 0 1.5em;
  line-height: 3.06rem;
  text-decoration: none;
  transition: all 0.2s;

  color: #00cc9b;
  background-color: transparent;
  border: 1px solid #00cc9b;
`;
